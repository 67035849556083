import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { SideBar } from './features/drawer';
import { ReportHubComp } from './screens/ReportHub';
import Toolbar from '@mui/material/Toolbar';
import { Login } from './screens/Login';
import { ForgotPassword } from './screens/ForgotPassword';
import { ResetPassword } from './screens/ResetPassword';
import { useSelector } from 'react-redux';
import { LoadingProvider } from './context/loading.context';
import React, { useEffect, useState } from 'react';
import './App.css';
import { Helmet } from 'react-helmet';

var worklistName = "New Report CoreLife";

const whichWorklist = () => {
  let url = window.location.href.toLowerCase();
  if (url.includes('patientnavigator')) {
    worklistName = "Patient Navigator Report";
  } else if (url.includes('vobnpa') || url.includes('vobreverify') || url.includes('vobunable')) {
    worklistName = "VOB Report";
  } else if (url.includes('accountlookup')) {
    worklistName = "Account Lookup";
  } else if (url.includes('om-dailyaudit') || url.includes('om-confirmation') || url.includes('om-past')
    || url.includes('om-volume') || url.includes('om-restart') || url.includes('om-incompletemovement')
    || url.includes('om-abnneeded') || url.includes('om-activewithoutnfv')
    || url.includes('om-npafaxed') || url.includes('om-unabletoverify') || url.includes('om-npabeforetoday')
    || url.includes('om-bhstatus') || url.includes('om-missingbhpaperwork') || url.includes('om-unblverifymanagedins')
    || url.includes('om-parequired')) {
    worklistName = "OM Reports"
  } else if (url.includes('newpatientform')) {
    worklistName = "New Patient Forms";
  }else if (url.includes('programconsentlinks')) {
    worklistName = "Program Consent Links";
  } else {
    worklistName = "New Report CoreLife";
  }
}

function App() {
  const [width, setWidth] = React.useState(0);

  const state = useSelector((s: any) => {
    return (s as any).loginReducer;
  });
  return (
    <div style={{ display: 'flex' }}>
      <LoadingProvider>
        {whichWorklist()}
        {worklistName == "Patient Navigator Report" &&
          <Helmet>
            <title>{worklistName}</title>
          </Helmet>}
        {worklistName == "VOB Report" &&
          <Helmet>
            <title>{worklistName}</title>
          </Helmet>}
        {worklistName == "Account Lookup" &&
          <Helmet>
            <title>{worklistName}</title>
          </Helmet>}
        {worklistName == "OM Reports" &&
          <Helmet>
            <title>{worklistName}</title>
          </Helmet>
        }{worklistName == "New Patient Forms" &&
          <Helmet>
            <title>{worklistName}</title>
          </Helmet>
        }{worklistName == "Program Consent Links" &&
          <Helmet>
            <title>{worklistName}</title>
          </Helmet>
        }
        <Router>

          {/* <SideBar setWidth={setWidth} /> */}
          <div style={{ height: '100vh', width: '100%', flexGrow: 1 }}>
            <Toolbar />
            <div style={{ height: 'calc(100% - 64px)' }}>
              <Routes>
                <Route path="*" element={<Navigate to="/" replace />}></Route>
                <Route path="/" element={<ReportHubComp />}></Route>
                <Route path="/corelife/*" element={<ReportHubComp />}></Route>
                <Route path="/Tulane/*" element={<ReportHubComp />}></Route>
              </Routes>
            </div>
          </div>

        </Router>
      </LoadingProvider>
    </div>
  );
}

export default App;

export const exportReportToCSV = async (currentView: string, reportData: any, addSep: boolean) => {
    let url = window.location.href.toLowerCase();

    if (url.includes('patientnavigator2')){
      currentView = 'patientnavigator2';
    }
    else if(url.includes('patientnavigator')){
      currentView = 'patientnavigator';
    }

    let columns: any[] = [];
    let reportTitle = "";
    switch (currentView) {
        case 'om-dailyaudit':
            reportTitle = 'Daily Audit Report';
            columns = [
                ["Location", "Patient Name", "MRN", 'Next Follow Up Visit Date', 'CustomerId', 'Username'], // Specify your headers here
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item.NFVisitDate, item.CustomerId, item.Username]) // Map your data fields accordingly
              ];
              break;
        case 'om-confirmation':
            reportTitle = 'NPA Confirmation Calls Report';
            columns = [
                ['Location', 'MRN', 'Patient Name', 'Patient Lead Status', 'NPA Confirmation Call-Date', 'Insurance Verified', 'Medical Benefits', 'NP Visit Date', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.MRN, item.FullName, item['Patient Lead Status'], item['NPA Confirmation Call-Date'], item['Insurance Verified'], item['Medical Benefits'], item['NP Visit Date’'], item.CustomerId, item.Username])
              ];
              break;
        case 'om-past':
            reportTitle = 'Follow Up In Past Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'Next Follow Up Visit Date', 'Last Follow Up Visit', 'Last Contacted Date', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item['NFVisitDate'], item['Last Follow Up Visit'], item['Last Contacted Date'], item.CustomerId, item.Username])
              ];
              break;
        case 'om-volume':
            reportTitle = 'Volume Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'Managed Patient Status', 'Primary Insurance', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item['Patient Status'], item['Primary Insurance'], item.CustomerId, item.Username])
              ];
              break;
        case 'om-restart':
            reportTitle = 'Restart Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'Managed Patient Status', 'Last Follow Up Visit', 'Last Contacted Date', 'Last Contacted Time', 'Next Follow Up Visit Date', 'Next Follow Up Visit Time', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item['Patient Status'], item['Last Follow Up Visit'], item['Last Contacted Date'], item['Last Contacted Time'], item['Next Follow Up Visit Date'], item['Next Follow-up Visit Time'], item.CustomerId, item.Username])
              ];
              break;
        case 'om-incompletemovement':
            reportTitle = 'Incomplete Movement Consult Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'DOB', 'Email', 'Mobile Phone', 'Movement Consult Status', 'Movement Consult Date', 'Movement Consult Time', 'Next Follow Up Visit Date', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item.DOB, item.EmailId, item.ContactNo, item['Movement Consult Status'], item['Movement Consult Date'], item['Movement Consult Time'], item['Next Follow Up Visit Date'], item.CustomerId, item.Username])
              ];
              break;
        case 'om-abnneeded':
            reportTitle = 'ABN Needed Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'DOB', 'Next Follow Up Visit Date', 'Next Follow Up Visit Time', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item.DOB, item['Next Follow Up Visit Date'], item['Next Follow-up Visit Time'], item.CustomerId, item.Username])
              ];
              break;
        case 'om-activewithoutnfv':
            reportTitle = 'Active Without Next Follow-Up Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'DOB', 'Last Follow Up Visit', 'Mobile Phone', 'Email', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item.dtDOB, item['Last Follow Up Visit'], item.ContactNo, item.EmailId, item.CustomerId, item.Username])
              ];
              break;
        case 'om-npafaxed':
            reportTitle = 'NPA to be Faxed Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'DOB', 'NP Visit Date', 'NP Visit Time', 'Patient Referring Provider', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item.DOB, item['NP Visit-Date'], item['NP Visit-Time'], item['PatRefProvider'], item.CustomerId, item.Username])
              ];
              break;
        case 'om-npabeforetoday':
            reportTitle = 'NPA Before Today (Leads) Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'DOB', 'NP Visit Date', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item.DOB, item['NP Visit-Date'], item.CustomerId, item.Username])
              ];
              break;
        case 'om-unabletoverify':
            reportTitle = 'Unable to Verify Insurance (Leads) Report';
            columns = [
                ['Location', 'MRN', 'Patient Name', 'DOB', 'Patient Lead Status', 'Status of Benefits', 'Insurance Verified', 'NP Visit Date', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.MRN, item.FullName, item.DOB, item['Patient Lead Status'], item['Status of Benefits'], item['Insurance Verified'], item['NP Visit-Date'], item.CustomerId, item.Username])
              ];
              break;
        case 'om-bhstatus':
            reportTitle = 'Behavioral Health Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'DOB', 'Managed Patient Status', 'BH Follow Up Date', 'BH Follow Up Time', 'Phone Number', 'Email', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item.DOB, item['Patient Status'], item['BH Follow Up Date'], item['BH Follow Up Time'], item.ContactNo, item.EmailId, item.CustomerId, item.Username])
              ];
              break;
        case 'om-missingbhpaperwork':
            reportTitle = 'Missing BH Paperwork Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'DOB', 'Managed Patient Status', 'BH Status', 'BH Follow Up Date', 'BH Follow Up Time', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item.DOB, item['Patient Status'], item['BH Status'], item['BH Follow Up Date'], item['BH Follow Up Time'], item.CustomerId, item.Username])
              ];
              break;
         case 'om-unblverifymanagedins':
            reportTitle = 'Unable to Verify Insurance (Managed) Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'DOB', 'Managed Patient Status', 'Status of Benefits', 'Next Follow Up Visit Date', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item.DOB, item['Patient Status'], item['Status of Benefits'], item['NFVisitDate'], item.CustomerId, item.Username])
                ];
             break;
        case 'om-parequired':
            reportTitle = 'PA Required Report';
            columns = [
                ['Location', 'Patient Name', 'MRN', 'DOB', 'Next Follow Up Visit Date', 'Auth End Date', 'Auth Visits Remaining', 'CustomerId', 'Username'],
                ...reportData.map((item: any) => [item.OrganizationName, item.FullName, item.MRN, item.DOB, item['Next Follow Up Visit Date'], item['Auth End Date'], item['Remaining Visits'], item.CustomerId, item.Username])
                ];
             break;
          case 'accountLookup':
            reportTitle = 'Account Lookup Report';
            columns = [
                ["Created Date", "Account Created By", "Location", 'Patient Name', 'DOB', 'MRN', 'Phone Number', 'Username'],
                ...reportData.map((item: any) => [item.ActionDate, item.AccountCreatedBy, item.OrganizationName, item.FullName, item.DOB, item.MRN, item.ContactNo, item.Username])
              ];
              break;
          case 'newPatientForm':
            reportTitle = 'NPA form Links Report';
            columns = [
                ["Username", "Name", "Email", 'Sub Org', 'Org Id', 'NP Visit Date', 'NP Visit Time', 'NPA Form Link', 'Form Results'],
                ...reportData.map((item: any) => [item.Username, item.FullName, item.EmailId, item.OrganizationName, item.OrgId, item.NPVisitDate, item['NP Visit-Time'], item.NPAFormLink, (item.NPAJsonAvailable === true ? 'Yes' : 'No')])
              ];
              break;
          case 'programconsentlinks':
            reportTitle = 'Program Consent Links Report';
            columns = [
                ["CustomerId", "Username", "Name", 'Program Consent Link', 'Consent Results'],
                ...reportData.map((item: any) => [item.CustomerId ,item.Username, item.FullName, item.PCFormLink, (item.PCJsonAvailable === true ? 'Yes' : 'No')])
              ];
              break;
          case 'patientnavigator':
            reportTitle = 'New Lead Report';
            columns = [
                ['Patient Navigator', 'Patient Name', 'MRN', "Location", 'Lead Created Date', 'Duplicate Referral Date', 'Outreach Call Attempts', 'Contact patient on this date', 'Last Contacted Date', 'Days Since Created', 'Patient Lead Status', 'Availability', 'Consultation Completed', 'Days Since Contact Me', 'Username'],
                ...reportData.map((item: any) => [item['Patient Lead Owner'], item.FullName, item.MRN, item.OrganizationName, item.ActionDate, item['Duplicate Referral Date'], item['Outreach Call Attempts'], item['Contact Patient Date'], item['Last Contacted Date'], item.Since, item['Patient Lead Status'], item.PreferredContactTimeJson === "" ? 'Not Available' : 'Available', item['Phone Consult Completed'], isNaN(item['Since Contact']) ? '' : item['Since Contact'], item.Username])
              ];
              break;
          case 'patientnavigator2':
            reportTitle = 'Historic Lead Report';
            columns = [
                ['Patient Navigator', 'Patient Name', 'MRN', "Location", 'Lead Created Date', 'Duplicate Referral Date', 'Outreach Call Attempts', 'Contact patient on this date', 'Last Contacted Date', 'Days Since Created', 'Patient Lead Status', 'Availability', 'Consultation Completed', 'Days Since Contact Me', 'Username'],
                ...reportData.map((item: any) => [item['Patient Lead Owner'], item.FullName, item.MRN, item.OrganizationName, item.ActionDate, item['Duplicate Referral Date'], item['Outreach Call Attempts'], item['Contact Patient Date'], item['Last Contacted Date'], item.Since, item['Patient Lead Status'], item.PreferredContactTimeJson === "" ? 'Not Available' : 'Available', item['Phone Consult Completed'], isNaN(item['Since Contact']) ? '' : item['Since Contact'], item.Username])
              ];
              break;
          case 'vobnpa':
            reportTitle = 'VOB NPA Report';
            columns = [
                ['Patient Lead Status', 'Patient Name', 'DOB', 'Address', 'City', 'State', 'Mobile Phone', 'Home Phone', 'Primary Insurance', 'Primary ID Number', 'Location of Interest', 'NP Visit Scheduled', 'NP Visit-Date', 'Days from NPA', 'Insurance Verified', 'Verification Owner', 'Latest Entry', 'Last Contacted Date', 'Username'],
                ...reportData.map((item: any) => [item['Patient Lead Status'], item.FullName, item.DOB, item.Address1, item.City, item.State, item.ContactNo, item.HomePhone, item['Primary Insurance'], item['Primary ID Number'], item.OrganizationName, item['NP Visit Scheduled'], item['NP Visit-Date'], item['Days from NPA'], item['Insurance Verified'], item['Verification Owner'], item.ServerSyncTimestamp, item['Last Contacted Date'], item.Username])
              ];
              break;
          case 'vobreverify':
            reportTitle = 'VOB Reverify Report';
            columns = [
                ['Patient Name', 'DOB', 'Address', 'City', 'State', 'Primary Insurance', 'Primary ID Number', 'Location', 'NP Visit Scheduled', 'NP Visit-Date', 'Established Restart Date', 'Username'],
                ...reportData.map((item: any) => [item.FullName, item.DOB, item.Address1, item.City, item.State, item['Primary Insurance'], item['Primary ID Number'], item.OrganizationName, item['NP Visit Scheduled'], item['NP Visit-Date'], item['Established Restart'], item.Username])
              ];
              break;
          case 'vobunable':
            reportTitle = 'VOB Unable Report';
            columns = [
                ['Patient Name', 'DOB', 'Address', 'City', 'State', 'Primary Insurance', 'Primary ID Number', 'Location', 'NP Visit Scheduled', 'NP Visit-Date', 'Established Restart Date', 'Username'],
                ...reportData.map((item: any) => [item.FullName, item.DOB, item.Address1, item.City, item.State, item['Primary Insurance'], item['Primary ID Number'], item.OrganizationName, item['NP Visit Scheduled'], item['NP Visit-Date'], item['Established Restart'], item.Username])
              ];
              break;
        default:
          return;
      }
  
  downloadReport(columns.map(row => row.join(";")).join("\n"), reportTitle, addSep);  
}

function downloadReport(csvString: string, reportTitle: string, addSep: boolean) {
    
    const csvData = new Blob([(addSep ? 'sep=;'+'\n' : '') + csvString], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${reportTitle}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
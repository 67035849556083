import { CustomTable } from '../../components/table';
import { getDropDownValues } from '../../services/ReportAPIService';
import { getState } from '../../store';
import { IntlProvider } from 'react-intl';
import { LoginState } from '../../store/slices/login/type';
import { orgParenting, log } from '../../utils/util';
import { userPermission } from '../../services/AuthAPIService';
import Box from '@mui/material/Box';
import BoxContainer from './components/boxContainer';
import dayjs from 'dayjs';
import Header from './components/header';
import React, { useEffect } from 'react';

export const PatientNavigator = (props: any) => {
  //1st row
  const [locationProp, setLocationProp] = React.useState([]);
  const [leadStatus, setLeadStatus] = React.useState([]);
  const [columnFilterValue, setColumnFilterValue] = React.useState([]);
  const [days, setDays] = React.useState([0]);
  const [calls, setCalls] = React.useState([]);
  const [waitList, setWaitList] = React.useState([]);

  //2nd row
  const [navigator, setNavigator] = React.useState([]);
  const [name, setName] = React.useState([]);
  const [mrn, setMrn] = React.useState([]);
  const [insurance, setInsurance] = React.useState([]);
  const [fetched, setFetched] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [creationDate, setCreationDate] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [leadsDrop, setLeadsDrop] = React.useState(['']);
  const [columnDrop, setColumnDrop] = React.useState(['']);
  const [callsDrop, setCallsDrop] = React.useState(['']);
  const [waitDrop, setWaitDrop] = React.useState(['']);
  const [navDrop, setNavDrop] = React.useState(['']);
  const [insuranceDrop, setInsuranceDrop] = React.useState(['']);

  const session: LoginState = getState().loginReducer;

  let columns = [
    { label: 'Patient Navigator', accessor: 'Patient Lead Owner', sortable: true, width: '200px' },
    { label: 'Patient Name', accessor: 'FullName', sortable: true, width: '200px' },
    { label: 'MRN', accessor: 'MRN', sortable: true, width: '120px' },
    { label: 'Location', accessor: 'OrganizationName', sortable: true, width: '150px' },
    { label: 'Lead Created Date', accessor: 'ActionDate', sortable: true, width: '140px' },
    { label: 'Duplicate Referral Date', accessor: 'Duplicate Referral Date', sortable: true, width: '130px' },
    { label: 'Outreach Call Attempts', accessor: 'Outreach Call Attempts', sortable: true, width: '140px' },
    { label: 'Contact patient on this date', accessor: 'Contact Patient Date', sortable: true, width: '150px' },
    { label: 'Last Contacted Date', accessor: 'Last Contacted Date', sortable: true, width: '150px' },
    { label: 'Days Since Created', accessor: 'Since', sortable: true, width: '130px', isRightAlign: true },
    { label: 'Patient Lead Status', accessor: 'Patient Lead Status', sortable: true, width: '140px' },
    { label: 'Availability', accessor: 'PreferredContactTimeJson', sortable: true, width: '150px', action: 'clickable', actionEvent: props.onClickAvailabilityView },
    { label: 'Consultation Completed', accessor: 'Phone Consult Completed', sortable: true, width: '100px' },
    { label: 'Days Since Contact Me', accessor: 'Since Contact', sortable: true, width: '130px', isRightAlign: true },
    { label: 'Username', accessor: 'Username', sortable: true, width: '300px', actionEvent: props.onClickCopy }
  ];

  // table
  props.tablePars.columns = columns;

  let kindBoxes1: any =
  {
    type: 'Joy',
    boxes: [
      { name: 'Location', array: orgParenting(), value: locationProp, setValue: setLocationProp, handle: props.handleParenting, accessor: 'OrganizationName', type: 'multiSelectGroup' },
      { name: 'Patient Lead Status', array: leadsDrop, value: leadStatus, setValue: setLeadStatus, handle: props.handleMul, accessor: 'Patient Lead Status', type: 'multiSelectGroup' },
      // { name: 'Days Since Created', value: days, setValue: setDays, handle: props.handleMul, accessor: 'Since', type: 'lowerUpper', resetValue: [0, 90] },
      { name: 'Outreach Call Attempts', array: callsDrop, value: calls, setValue: setCalls, handle: props.handleMul, accessor: 'Outreach Call Attempts', type: 'multiSelectGroup' },
      { name: 'Waitlist', array: waitDrop, value: waitList, setValue: setWaitList, handle: props.handleMul, accessor: 'Waitlist Options', type: 'multiSelectGroup' },
      { name: 'Primary Insurance', array: insuranceDrop, value: insurance, setValue: setInsurance, handle: props.handleMul, accessor: 'Primary Insurance', type: 'multiSelectGroup' },
    ]
  }
  let kindBoxes2: any =
  {
    type: 'Joy',
    boxes: [
      { name: 'Patient Navigator', array: navDrop, value: navigator, setValue: setNavigator, handle: props.handleMul, accessor: 'Patient Lead Owner', type: 'multiSelectGroup' },
      { name: 'Patient Name', value: name, setValue: setName, handle: props.handleMul, accessor: 'FullName', type: 'textField' },
      { name: 'MRN', value: mrn, setValue: setMrn, handle: props.handleMul, accessor: 'MRN', type: 'textField' },
      { name: 'Count', value: count, setValue: setCount, type: 'label' },
    ]
  }

  function retKindBox1() {
    if (currentPage() == 'patientnavigator') {
      kindBoxes1.boxes.splice(2, 0, { name: 'Days Since Created', value: days, setValue: setDays, handle: props.handleMul, accessor: 'Since', type: 'lowerUpper', resetValue: [0, 90] });
      kindBoxes2.boxes.splice(3, 0, { name: 'Duplicate Referral Date', array: columnDrop, value: columnFilterValue, setValue: setColumnFilterValue, handle: props.handleQuery, accessor: 'Duplicate Referral Date', type: 'emptyCheckFilter' });
    }
    return kindBoxes1;
  }

  function retKindBox2() {
    if (currentPage() == 'patientnavigator2') {
      kindBoxes2.boxes.splice(3, 0, {
        name: 'Account Creation Date', value: creationDate, setValue: setCreationDate, handle: props.handleDate, accessor: 'Creation Date', type: 'dateRangePicker',
        resetValue: [
          dayjs('2013/01/01').format('YYYY/MM/DD'),
          dayjs(Date.now()).subtract(91, 'day').format('YYYY/MM/DD'),
        ],
        toast: props.toast,
        clickOnRefresh: props.clickOnRefresh,
        refreshBtnDisable: props.refreshBtnDisable,
      });
      kindBoxes2.boxes.splice(4, 0, { name: 'Duplicate Referral Date', array: columnDrop, value: columnFilterValue, setValue: setColumnFilterValue, handle: props.handleQuery, accessor: 'Duplicate Referral Date', type: 'emptyCheckFilter', resetValue: ['Not Empty'] });
    }
    return kindBoxes2;
  }

  //runs after each render
  useEffect(() => {
    // log('render patNav');
    setCount(props.count);
  });

  //runs if any on the list changes
  useEffect(() => {
    // log('render patNav, Changed one');
  }, [locationProp, leadStatus, days, calls, waitList, navigator, name, mrn, insurance]);

  //runs one time when session is changed
  useEffect(() => {
    if (!session.isLoggedIn || !session.sessionData) {
      return;
    }

    props.clearQueryInputs();
    fetchData();
    clearAllFilters();

  }, [session, window.location.href]);

  function currentPage() {
    let url = window.location.href.toLowerCase();

    if (url.includes('patientnavigator2'))
      return 'patientnavigator2'
    if (url.includes('patientnavigator'))
      return 'patientnavigator'

    return '';
  }

  const map = [
    // { key: 'Patient Lead Status', setValue: setLeadsDrop },
    // { key: 'Outreach Call Attempts', setValue: setCallsDrop },
    { key: 'Waitlist Options', setValue: setWaitDrop },
    { key: 'Patient Lead Owner', setValue: setNavDrop },
    { key: 'Primary Insurance', setValue: setInsuranceDrop },
  ];

  const fetchData = async () => {
    setLoading(true);

    let r3 = await userPermission('patientNavigator');
    if (!r3.IsSuccess) {
      props.navigateOut();
      return;
    }

    if (props.originData.length === 0)
      await props.fetchOrganizations();

    try {
      // log('fetchData');

      map.forEach(async (it) => {
        let response = await getDropDownValues(it.key, 'patientNavigator');
        if (response.IsSuccess)
          it.setValue((response.Data.Data.map((item: any) => item.Response)));
      });

      setLeadsDrop(['New Lead', 'New Lead 1', 'New Lead 2', 'Contacted']);
      setColumnDrop(["Not Empty"]);
      setCallsDrop(['PN Call 1', 'PN Call 2', 'Office Call 1', 'Office Call 2']);

      setResetVals();

    } catch (error) {
    } finally {
      setLoading(false);
      props.setRefreshBtnDisable(false);
    }
  };

  //pre selection
  function setResetVals(skip: any = null) {
    let boxes = [...kindBoxes1.boxes, ...kindBoxes2.boxes];

    for (let i = 0; i < boxes.length; i++) {
      const el = boxes[i];
      if (el.resetValue) {
        if (el.accessor === skip) continue;
        el.setValue(el.resetValue);
      }
    }
  }

  function clearAllFilters() {
    //restart all filters 
    setLocationProp([]);
    setLeadStatus([]);
    setColumnFilterValue([]);
    setCalls([]);
    setWaitList([]);
    setNavigator([]);
    setName([]);
    setInsurance([]);

    setResetVals(currentPage() == 'patientnavigator' ? 'Fetched for days' : 'Creation Date');
    props.clearFilters();
  }

  return (
    <IntlProvider messages={{}} locale='de' defaultLocale='en'>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Header
            clearAllFilters={clearAllFilters}
            clickOnRefresh={props.clickOnRefresh}
            clickOnExport={props.clickOnExport}
            refreshBtnDisable={props.refreshBtnDisable}
            refreshed={props.refreshed}
            title={currentPage() == 'patientnavigator' ? 'New Lead Report' : 'Historic Lead Report'}
            subTitle={currentPage() == 'patientnavigator' ? 'Accounts created in the last 90 days' : 'Accounts created over 90 days ago'}
          />
          <BoxContainer  {...retKindBox1()} />
          <BoxContainer  {...retKindBox2()} />
          <div><p></p></div>
          <CustomTable {...props.tablePars} />
        </Box>
      </Box>
    </IntlProvider>
  );
};

export default React.memo(PatientNavigator);
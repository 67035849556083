export function retFlagName(reportName: any) {
  if (reportName === 'vobnpa')
    return 'CL_FastReport_VOB_NPA';

  else if (reportName.toLowerCase() === 'vobreverify' || reportName.toLowerCase() === 'vobunable')
    return 'CL_FastReport_VOB_Reverify_Unable';

  else if (reportName.toLowerCase() === 'accountlookup')
    return 'CL_FastReport_AccountLookup';

  else if (reportName.toLowerCase() === 'patientnavigator')
    return 'CL_FastReport_PatientNavigator';
  
  else if (reportName.toLowerCase() === 'om-dailyaudit' || reportName.toLowerCase() === 'om-confirmation'  || reportName.toLowerCase() === 'om-past'  || reportName.toLowerCase() === 'om-volume'  || reportName.toLowerCase() === 'om-restart' || reportName.toLowerCase() === 'om-incompletemovement' || reportName.toLowerCase() === 'om-abnneeded'  || reportName.toLowerCase() === 'om-activewithoutnfv' || reportName.toLowerCase() === 'om-npafaxed' || reportName.toLowerCase() === 'om-unabletoverify' || reportName.toLowerCase() === 'om-npabeforetoday' || reportName.toLowerCase() === 'om-bhstatus' || reportName.toLowerCase() === 'om-missingbhpaperwork' || reportName.toLowerCase() === 'om-unblverifymanagedins' || reportName.toLowerCase() === 'om-parequired')
    return 'CL_FastReport_OM';

  else if (reportName.toLowerCase() === 'newpatientform')
    return 'CL_FastReport_NPAIntakeForm';

  else if (reportName.toLowerCase() === 'programconsentlinks')
    return 'TLN_FastReport_ProgramConsentForm';

  return 'CL_FastReport_Default';
}
import { Column } from './table';
import { returnWidth } from './util';
import { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

export interface TableHeadProps {
  columns: Column[];
  handleSorting: any;
}

const CustomTableHead = ({ columns, handleSorting }: TableHeadProps) => {
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const handleSortingChange = (accessor: any) => {
    const sortOrder =
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column: any) => {
          const icon = column.sortable ? (
            sortField === column.accessor && order === 'asc' ? (
              <ExpandLessIcon color="primary" />
            ) : sortField === column.accessor && order === 'desc' ? (
              <ExpandMoreIcon color="primary" />
            ) : (
              <UnfoldMoreIcon color="primary" />
            )
          ) : (
            ''
          );
          return (
            <TableCell
              style={{ cursor: 'pointer', overflow: 'hidden', height: '100%' }}
              key={column.label}
              onClick={
                column.sortable
                  ? () => handleSortingChange(column.accessor)
                  : undefined
              }
              sx={{
                fontFamily: 'ProximaNovaSemibold',
                fontSize: 18,
                border: "1px solid rgba(224, 224, 224, 1)",
                color: 'white',
                textAlign: 'left',
                padding: '5px 5px 5px 5px',
                backgroundColor: '#58595b',
              }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'self-start',
                height: '45px',
                textAlign: 'left',
                minWidth: returnWidth(column, columns),
              }}>

                <div style={{ width: '100%' }}>
                  {column.label}
                </div>
                <IconButton
                  title='Sort'
                  type="button"
                  sx={{ p: 0, fontSize: '20px' }}
                  onClick={() => { }}
                >
                  {icon}
                </IconButton>
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;

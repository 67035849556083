import './multiSelect.css';
import { BasicDatePicker } from './datePicker';
import { Button, IconButton } from '@mui/material';
import { TextField } from '@mui/material';
import { uuidv4, log } from '../../../utils/util';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect } from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Select from '@mui/material/Select';

const KindBox = (props: any) => {
  const [placeholder, setPlaceholder] = React.useState(definePlaceholder(props.props.value));

  let mulVal: any;
  let lower: any;
  let upper: any;

  useEffect(() => {
    if (Array.isArray(props.props.value) && (props.props.type === 'multiSelectGroup' || props.props.type === 'columnFilter' || props.props.type === 'emptyCheckFilter' || props.props.type === 'checkMultiValueFilter')) {
      setPlaceholder(definePlaceholder(props.props.value));
      addListener();
    }
    if (props.props.type === 'textField') {
      addListener();
    }
    if (props.props.type === 'lowerUpper') {
      // log('lowerUpper', props.props);
      lower = props.props.value[0];
      upper = props.props.value[1];
    }
  }, [props.props]);

  let multiselectRef = React.useRef<Multiselect>(null);

  function multiOnClear() {
    // By calling the below method will reset the selected values programatically
    if (multiselectRef != null && multiselectRef.current != null)
      multiselectRef.current.resetSelectedValues();
    doTrailOfMultiTask(props.props.resetValue || []);
  }

  function lowerUpperOnReset() {
    doTrailOfMultiTask(props.props.resetValue);
  }

  function addListener() {
    let el = document.getElementById(props.props.name + '.id_input');
    if (el)
      el.addEventListener('blur', (event) => {
        // log(mulVal);
        if (mulVal)
          doTrailOfMultiTask(mulVal);
      })

    if (props.props.type === 'textField') {
      // debugger;
      let el = document.getElementById(props.props.name + '.input');
      // log('el', el)

      if (el) {
        el.addEventListener('keypress', function (e: any) {
          // log('e', e)
          if (e.key === 'Enter') {
            // code for enter
            onBlurTextField();
          }
        });
      }

    }
  }

  function multiOnSelect(param: any) {
    // doTrailOfMultiTask(param);
    mulVal = param;
  }

  function multiOnRemove(param: any) {
    // doTrailOfMultiTask(param);
    mulVal = param;
  }

  function multiOnKey(param: any) {
    // log(param);
  }

  function doTrailOfMultiTask(param: any) {
    // log(param);
    props.props.setValue(param);
    props.props.handle(param, props.props.accessor, props.props);
  }

  function definePlaceholder(param: any) {
    return param.length === 0 ? 'All' : param.length + ` ${param.length === 1 ? 'filter' : 'filters'} selected`;
  }

  function onChangeTextField(event: any) {
    // log(event, event.target.value);

    //cant be negative
    if (props.props.accessor === 'Fetched for days') {
      let nu = parseInt(event.target.value);
      if (nu <= 0) {
        mulVal = [1];
        return;
      }
    }

    let arr = event.target.value.trim().length === 0 ? [] : [event.target.value];
    mulVal = arr;
  }

  function onBlurTextField() {
    // log(mulVal);
    if (mulVal === undefined) return;
    props.props.setValue(mulVal);
    props.props.handle(mulVal, props.props.accessor, props.props);
  }

  function onChangeTextFieldLower(event: any) {
    // log(event, event.target.value);
    let v = event.target.value.trim().length === 0 ? undefined : event.target.value;
    if (v === undefined) return;
    lower = parseInt(v) || 0;
  }

  function onChangeTextFieldUpper(event: any) {
    // log(event, event.target.value);
    let v = event.target.value.trim().length === 0 ? undefined : event.target.value;
    if (v === undefined) return;
    upper = parseInt(v) || 0;
  }

  function onBlurTextFieldLowerUpper() {
    if (lower == props.props.value[0] && upper == props.props.value[1]) return;

    props.props.setValue([lower, upper]);
    props.props.handle([lower, upper], props.props.accessor, props.props);
  }

  function renderMenuItems(array: any) {
    // log('array2', array);

    if (!array) return;

    let render = [];
    for (let i = 0; i < array.length; i++) render.push(<MenuItem key={uuidv4()} value={array[i]}>{array[i]}</MenuItem>);
    return render;
  }

  const styleFormControl = () => {
    let style = { marginLeft: 0, minHeight: 0, width: '100%', maxHeight: 333 };

    if (props.props.type === 'label')
      return { ...style, ...{ minHeight: 50, fontWeight: 'bold', fontSize: '1.7em', display: 'flex', alignItems: 'center', justifyContent: 'center' } };

    return style;
  }

  const stylePropName = () => {
    let style: any = { margin: '0', paddingBottom: 2, fontSize: '1.1em', color: '#004579', fontWeight: 'bold', width: 'calc(100% - 24px)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

    style.textAlign = 'left';

    if (props.props.type === 'label')
      return { ...style, ...{ width: '100%', textAlign: 'center' } };

    return style;
  }

  return (
    <div key={uuidv4()} style={{ padding: 1 }}>
      <Box className='kindBoxCont' style={{
        padding: 6, minWidth: props.props.width + 'vw',
        maxWidth: props.props.width + 'vw',
        width: '100%',
        // border: '1px solid #cccccc3d',
        borderRadius: '12px'
      }}>
        <p style={stylePropName()} title={props.props.name}>{props.props.name}</p>
        <FormControl style={styleFormControl()}>

          {/* default type is a select */}
          {!props.props.type && <Select style={{ borderRadius: 10, height: 50, fontWeight: 'bold', fontSize: '1.1em' }}
            value={props.props.value}
            onChange={(event) => {
              props.props.setValue(event.target.value);
              props.props.handle(event.target.value, props.props.accessor);
            }}
          >
            {renderMenuItems(props.props.array)}
          </Select>}

          {/* type: label */}
          {props.props.type === 'label' && <div>{props.props.value}</div>}

          {/* type: textField */}
          {props.props.type === 'textField' && <div>

            <IconButton title='Reset Filter'
              aria-haspopup='true'
              onClick={multiOnClear}
              color='primary'
              style={{ position: 'absolute', top: -36, right: -9 }}
            >
              <RestartAltIcon sx={{ fontSize: 24 }} />
            </IconButton>

            <TextField
              id={props.props.name + '.input'}
              type={props.props.textType || 'text'}
              placeholder='All'
              defaultValue={props.props.value}
              onBlur={onBlurTextField}
              onChange={onChangeTextField}
              inputProps={
                {
                  sx: {
                    '&::placeholder': {
                      color: 'black',
                      opacity: 1, // otherwise firefox shows a lighter color
                    },
                  },
                }
              }
            >
            </TextField>
          </div>}

          {/* type: multiSelectGroup */}
          {(props.props.type === 'multiSelectGroup' || props.props.type === 'columnFilter' || props.props.type === 'emptyCheckFilter' || props.props.type === 'checkMultiValueFilter') && <div>

            <IconButton title='Reset Filter'
              aria-haspopup='true'
              onClick={multiOnClear}
              color='primary'
              style={{ position: 'absolute', top: -36, right: -9 }}
            >
              <RestartAltIcon sx={{ fontSize: 24 }} />
            </IconButton>

            <Multiselect
              id={props.props.name + '.id'}
              isObject={props.props.name === 'Location' ? true : false}
              ref={multiselectRef}
              selectedValues={props.props.value}
              hideSelectedList
              avoidHighlightFirstOption={true}
              showCheckbox
              showArrow
              displayValue='key'
              groupBy={props.props.name === 'Location' ? 'cat' : ''}
              placeholder={placeholder}
              onKeyPressFn={multiOnKey}
              onRemove={multiOnRemove}
              onSelect={multiOnSelect}
              options={props.props.array}
              closeOnSelect={false}
            // singleSelect={false}
            />
          </div>}

          {/* type: lowerUpper */}
          {props.props.type === 'lowerUpper' && <div>
            <IconButton title='Reset Filter'
              aria-haspopup='true'
              onClick={lowerUpperOnReset}
              color='primary'
              style={{ position: 'absolute', top: -36, right: -9 }}
            >
              <RestartAltIcon sx={{ fontSize: 24 }} />
            </IconButton>

            <div style={{ display: 'flex' }}>

              <TextField
                type='number'
                title='Lower limit'
                defaultValue={props.props.value[0]}
                onBlur={onBlurTextFieldLowerUpper}
                onChange={onChangeTextFieldLower}
              >
              </TextField>

              <div style={{ width: 20 }}></div>

              <TextField
                type='number'
                title='Upper limit'
                defaultValue={props.props.value[1]}
                onBlur={onBlurTextFieldLowerUpper}
                onChange={onChangeTextFieldUpper}
              >
              </TextField>
            </div>
          </div>}

          {/* type: dateRangePicker */}
          {props.props.type === 'dateRangePicker' && <div>

            <IconButton title='Reset Filter'
              aria-haspopup='true'
              onClick={lowerUpperOnReset}
              color='primary'
              style={{ position: 'absolute', top: -36, right: -9 }}
            >
              <RestartAltIcon sx={{ fontSize: 24 }} />
            </IconButton>

            <div style={{ display: 'flex' }}>
              <BasicDatePicker {...props} />

              <Button
                title='Fetch Data Now'
                sx={{
                  marginLeft: '9.813px',
                  height: '49px',
                  fontSize: '18px',
                  fontFamily: 'ProximaNovaSoftSemibold',
                  width: '12%',
                  minWidth: 0, lineHeight: 0,
                }}
                variant='contained'
                size='small'
                disabled={props.props.refreshBtnDisable}
                onClick={() => {
                  props.props.clickOnRefresh();
                }}>Go</Button>
            </div>

          </div>}

        </FormControl>
      </Box>
    </div>
  );
};

export default React.memo(KindBox);

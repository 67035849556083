import 'react-toastify/dist/ReactToastify.css';
import { API_ERROR, mozzazReportingWebPortalLogin, getMozzazPortalOAuthToken, getMozzazCustomPortalOAuthToken, generateConsentLinkForTulane } from '../../services/AuthAPIService';
import { autoRefresh, clickOnRefresh } from '../../utils/refresh';
import { convertUTCDateToLocalDate, getSelectedOMReport, isOMReport, log } from '../../utils/util';
import { differenceInCalendarDays } from 'date-fns';
import { getState, dispatch, actions } from '../../store';
import { IntlProvider } from 'react-intl';
import { LoginData, LoginState } from '../../store/slices/login/type';
import { patientNavigatorQuery, VOBNPAQuery, VOBReverifyUnableQuery, AccountLookupQuery, OMDailyAuditQuery, OMNPAConfirmationCallsQuery, OMPastQuery, OMVolumeQuery, OMRestartQuery, OMInCompleteMovementQuery, OMABNNeededQuery, OMActiveWithoutNFVQuery, OMNPAFaxedQuery, OMUnableToVerifyQuery, NPAIntakeFormReportQuery, OMNPABeforeTodayLeadsQuery, OMBehavioralHealthStatusQuery, OMMissingBHPaperworkQuery, OMUnableVerifyInsManagedQuery, OMPARequiredQuery, ProgramConsentFormReportQuery } from '../../services/ReportAPIService';
import { CUSTOM_WEB_PORTAL, PORTAL } from '../../config/Constant';
import { ToastContainer, toast } from 'react-toastify';
import { useLoading } from '../../context/loading.context';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { VOBNPA } from './vobNpa';
import { VOBReverifyUnable } from './vobReverifyUnable';
import AccountLookup from './accountLookup';
import AppBarMenu from '../../components/AppBar';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter' // import plugin
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore' // import plugin
import PatientNavigator from './patientNavigator';
import React, { useEffect, useState } from 'react';
import { OMDailyAudit } from './omDailyAudit';
import { OMNPAConfirmationCalls } from './omNPAConfirmationCalls';
import { OMPast } from './omPast';
import { OMVolume } from './omVolume';
import { OMRestart } from './omRestart';
import { OMInCompleteMovement } from './omInCompleteMovement';
import { OMABNNeeded } from './omABNNeeded';
import { OMActiveWithoutNextFollowUpVisit } from './omActiveWithoutNFV';
import { OMNPAFaxed } from './omNPAFaxed';
import { OMUnableVerifyInsurance } from './omUnableVerifyIns';
import { NPAIntakeReport } from './NPA/npaIntakeReport';
import { OMNPABeforeToday } from './omNPABeforeToday';
import { OMBehavioralHealth } from './omBehavioralHealth';
import { OMMissingBHPaperwork } from './omMissingBHPaperwork';
import { exportReportToCSV } from '../../utils/reportDownload';
import { PopUp } from '../../components/PopUp';
import { OMUnableVerifyInsManaged } from './omUnableVerifyInsManaged';
import { OMPARequired } from './omPARequired';
import { ProgramConsentLinksReport } from './Tulane/programConsentLinks';

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
//filters applied are kept here, field is accessor
let filtersMulti: { accessor: string; value: any[]; type: any }[] = [];

let sortAccessor = 'Since';
let sortAsc = true;
let fetchedForDays = 40;

//this is for account creation date on report # 2
//also use for report # 1
let startDate = dayjs(Date.now()).format('YYYY/MM/DD');
let endDate = dayjs(Date.now()).format('YYYY/MM/DD');
let duplicateReferralDate = false;

interface PopupDetails {
  header: string;
  message: string;
  primaryBtnText: string;
  primaryBtnEvent?: Function;
  secondaryBtnText?: string;
  secondaryBtnEvent?: Function;
  open: boolean;
}

const ReportHub = (props: any) => {
  const [queryRes, setQueryRes] = useState<any[]>([]);
  const [filteredQueryRes, setFilteredQueryRes] = useState<any[]>([]);
  const [filteredQueryResPage, setFilteredQueryResPage] = useState<any[]>([]);

  const [currentView, setCurrentView] = React.useState('unauth');
  const [msg, setMsg] = React.useState('Not authorized user or incorrect web address.');
  const { loading, setLoading } = useLoading();
  const [open, setOpen] = React.useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const [refreshed, setRefreshed] = React.useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [refreshBtnDisable, setRefreshBtnDisable] = React.useState(true);

  const session: LoginState = getState().loginReducer;
  let location = useLocation();
  const navigate = useNavigate();

  const [popupDetails, setPopupDetails] = useState<PopupDetails>({
    header: "",
    message: "",
    primaryBtnText: "",
    primaryBtnEvent: () => {
      popupDetails.open = false;
      setPopupDetails(popupDetails);
    },
    secondaryBtnText: undefined,
    secondaryBtnEvent: undefined,
    open: false,
  });

  //runs all renders
  useEffect(() => {
    // log('render repHub');
    hubRouter();
    apiErrorMsg();
    autoRefresh(setRefreshBtnDisable, fetchOrganizations)
  });

  //runs in location change 
  useEffect(() => {
    // log('render orgList');
    validAndLogin();
  }, [location]);

  const validAndLogin = async () => {
    // log(session);
    if (session && session.isLoggedIn && session.sessionData) return;

    setLoading(true);
    let token = searchParams.get("token")

    if (!token) {
      setLoading(false);
      return;
    }

    //login
    let r2 = await mozzazReportingWebPortalLogin(token);
    if (r2.IsSuccess)
      dispatch(actions.loginActions.loginSuccess(r2.Data as LoginData));
    else
      setCurrentView('unauth');

    if (searchParams.has('token')) {
      searchParams.delete('token');
      setSearchParams(searchParams);
    }

    setLoading(false);
  }

  const hubRouter = () => {
    if (!session.isLoggedIn || !session.sessionData) return;

    //page routing
    if (location.pathname.toLowerCase().includes('/patientnavigator') && currentView != 'patientNavigator') {
      clearQueryInputs();
      sortAccessor = 'Since';
      hubRouterTrail();
      setCurrentView('patientNavigator');
    }

    if (location.pathname.toLowerCase().includes('/vobnpa') && currentView != 'vobnpa') {
      sortAccessor = 'Days from NPA';
      hubRouterTrail();
      setCurrentView('vobnpa');
    }

    if (location.pathname.toLowerCase().includes('/vobreverify') && currentView != 'vobreverify') {
      sortAccessor = 'Days from NPA';
      hubRouterTrail();
      setCurrentView('vobreverify');
    }

    if (location.pathname.toLowerCase().includes('/vobunable') && currentView != 'vobunable') {
      sortAccessor = 'Days from NPA';
      hubRouterTrail();
      setCurrentView('vobunable');
    }

    if (location.pathname.toLowerCase().includes('/accountlookup') && currentView != 'accountLookup') {
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('accountLookup');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-dailyaudit') && currentView != 'om-dailyaudit') {
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-dailyaudit');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-confirmation') && currentView != 'om-confirmation') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-confirmation');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-past') && currentView != 'om-past') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-past');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-volume') && currentView != 'om-volume') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-volume');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-restart') && currentView != 'om-restart') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-restart');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-incompletemovement') && currentView != 'om-incompletemovement') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-incompletemovement');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-abnneeded') && currentView != 'om-abnneeded') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-abnneeded');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-activewithoutnfv') && currentView != 'om-activewithoutnfv') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-activewithoutnfv');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-npafaxed') && currentView != 'om-npafaxed') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-npafaxed');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-unabletoverify') && currentView != 'om-unabletoverify') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-unabletoverify');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-npabeforetoday') && currentView != 'om-npabeforetoday') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-npabeforetoday');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-bhstatus') && currentView != 'om-bhstatus') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-bhstatus');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-missingbhpaperwork') && currentView != 'om-missingbhpaperwork') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-missingbhpaperwork');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-unblverifymanagedins') && currentView != 'om-unblverifymanagedins') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-unblverifymanagedins');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/om-parequired') && currentView != 'om-parequired') {
      clearQueryInputs();
      sortAccessor = 'ActionDate';
      hubRouterTrail();
      setCurrentView('om-parequired');
      sortAsc = false;
    }

    if (location.pathname.toLowerCase().includes('/newpatientform') && currentView != 'newPatientForm') {
      clearQueryInputs();
      sortAccessor = 'Since';
      hubRouterTrail();
      setCurrentView('newPatientForm');
    }

    if (location.pathname.toLowerCase().includes('/programconsentlinks') && currentView != 'programconsentlinks') {
      clearQueryInputs();
      sortAccessor = 'Since';
      hubRouterTrail();
      setCurrentView('programconsentlinks');
    }
  }

  const hubRouterTrail = () => {
    sortAsc = true;
    clearFilters();
    setQueryRes([]);
  }

  function clickOnRefreshHub() {
    clickOnRefresh(setRefreshBtnDisable, fetchOrganizations);
  }

  const onPageChanged = async (value: any, orgs: any = null) => {
    const res = paginate(orgs ? orgs : filteredQueryRes, 25, value);
    setFilteredQueryResPage(res);

    let total = orgs ? orgs.length : filteredQueryRes.length;
    setPagesCount(Math.ceil(total / 25));
    setCount(total);
    setPage(value);
  };

  async function clickOnExport() {
    if(filteredQueryRes.length === 0){
      setPopupDetails({
        header: "No Data Available for Export",
        message: "This report was not exported since there is no data available in the table. Please expand the range of the filters and try again.",
        primaryBtnText: "Ok",
        primaryBtnEvent: () => {
          popupDetails.open = false;
          setPopupDetails(popupDetails);
        },
        secondaryBtnText: undefined,
        secondaryBtnEvent: undefined,
        open: true,
      })
      return;
    } 

    setLoading(true);
    await exportReportToCSV(currentView, filteredQueryRes, true);
    setLoading(false);
  }

  function paginate(array: any, page_size: any, page_number: any) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  function duplicateData(arr: any) {
    let res: any[] = [];
    for (let i = 0; i < 2; i++) {
      res = res.concat(arr);
    }
    return res;
  }

  function clearFilters() {
    // log('clearFilters');
    filtersMulti = [];
    addFirstFilter();
    applyFiltersMulti();
  }

  function addFirstFilter() {
    if (filtersMulti.length > 0) return

    // log('addFirstFilter', location.pathname.toLowerCase());

    if (location.pathname.toLowerCase().includes('/vobnpa')) {
      filtersMulti.push({ accessor: 'Insurance Captured', value: ['Yes'], type: 'multiSelectGroup' });
      filtersMulti.push({ accessor: 'Insurance Verified', value: ['Blank', 'No'], type: 'multiSelectGroup' });
      filtersMulti.push({ accessor: 'NP Visit Scheduled', value: ['Yes'], type: 'multiSelectGroup' });
      filtersMulti.push({ accessor: 'Verification Owner', value: ['Blank'], type: 'multiSelectGroup' });
      filtersMulti.push({ accessor: 'Days from NPA', value: [0, 60], type: 'lowerUpper' });
    }
    else if (location.pathname.toLowerCase().includes('/vobreverify')) {
    }
    else if (location.pathname.toLowerCase().includes('/vobunable')) {
    }
    else if (location.pathname.toLowerCase().includes('/patientnavigator2')) {
      filtersMulti.push({ accessor: 'Duplicate Referral Date', value: ['Not Empty'], type: 'emptyCheckFilter' });
    }
    else if (location.pathname.toLowerCase().includes('/patientnavigator')) {
      filtersMulti.push({ accessor: 'Since', value: [0, 90], type: 'lowerUpper' });
    }
    else if (location.pathname.toLowerCase().includes('/om-activewithoutnfv')) {
      filtersMulti.push({ accessor: 'Patient Status', value: ['Active'], type: 'multiSelectGroup' });
    }
  }

  const fetchOrganizations = async (load: any = true) => {
    setLoading(load);
    try {
      // return;
      if (process.env.REACT_APP_ENV !== 'PROD')
        log('fetchOrganizations', window.location.href);

      let response: any;
      let newData: any;

      //accountLookup
      if (window.location.href.toLowerCase().includes('/accountlookup')) {
        response = await AccountLookupQuery();

        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let npDate = item['NP Visit-Date'];
            let start = new Date(npDate);
            let end = Date.now();
            let dif = differenceInCalendarDays(start, end) + 1;

            let actDate = item.ActionDate.substring(0, 10);
            let dob = item.DOB.substring(0, 10);
            let phone = item.ContactNo.replace(/\D/g, '');

            return {
              ...item, 'Days from NPA': dif, Since: dif, ActionDate: actDate, DOB: dob, ContactNo: phone
            };
          });

          //log('newData', newData);
        }
      }
      //vobreverify & vobunable
      else if (window.location.href.toLowerCase().includes('/vobreverify') || window.location.href.toLowerCase().includes('/vobunable')) {
        response = await VOBReverifyUnableQuery(window.location.href);

        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let npDate = item['NP Visit-Date'];
            let start = new Date(npDate);
            let end = Date.now();
            let dif = differenceInCalendarDays(start, end) + 1;

            let dob = item['DOB'].substring(0, 10);

            let syncDate = convertUTCDateToLocalDate(new Date(item['ServerSyncTimestamp']));
            let syDaSt = syncDate.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' });

            return {
              ...item, 'Days from NPA': dif, Since: dif, DOB: dob, ServerSyncTimestamp: syDaSt,
            };
          });

          //log('newData', newData);
        }
      }
      //om-dailyaudit
      else if (window.location.href.toLowerCase().includes('/om-dailyaudit')) {
        response = await OMDailyAuditQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);
            let start = new Date(actDate);
            let end = Date.now();
            let dif = differenceInCalendarDays(end, start) - 1;

            let cont = item['Next Follow Up Visit Date'];
            let startCont = new Date(cont);
            let difCont = differenceInCalendarDays(end, startCont) - 1;

            return {
              ...item, 'Since': dif, 'Since Contact': difCont, 'ActionDate': actDate, 'NFVisitDate': cont,
            };
          });

          // log('newData', newData);
        }
      }

      //om-confirmation
      else if (window.location.href.toLowerCase().includes('/om-confirmation')) {

        startDate = dayjs(Date.now()).format('YYYY/MM/DD');
        endDate = dayjs(Date.now()).add(32, 'day').format('YYYY/MM/DD');

        response = await OMNPAConfirmationCallsQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);
            let start = new Date(actDate);
            let end = Date.now();
            let dif = differenceInCalendarDays(end, start) - 1;

            let cont = item['NP Visit-Date'];
            let startCont = new Date(cont);
            let difCont = differenceInCalendarDays(end, startCont) - 1;

            return {
              ...item, 'Since': dif, 'Since Contact': difCont, 'ActionDate': actDate, 'NPVisitDate': cont,
            };
          });

          // log('newData', newData);
        }
      }

      //om-past
      else if (window.location.href.toLowerCase().includes('/om-past')) {

        startDate = dayjs(Date.now()).subtract(1825, 'day').format('YYYY/MM/DD');
        endDate = dayjs(Date.now()).subtract(1, 'day').format('YYYY/MM/DD');

        response = await OMPastQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);
            let start = new Date(actDate);
            let end = Date.now();
            let dif = differenceInCalendarDays(end, start) - 1;

            let cont = item['Next Follow Up Visit Date'];
            let startCont = new Date(cont);
            let difCont = differenceInCalendarDays(end, startCont) - 1;

            return {
              ...item, 'Since': dif, 'Since Contact': difCont, 'ActionDate': actDate, 'NFVisitDate': cont,
            };
          });

          // log('newData', newData);
        }
      }

      //om-volume
      else if (window.location.href.toLowerCase().includes('/om-volume')) {
        response = await OMVolumeQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);
            let start = new Date(actDate);
            let end = Date.now();
            let dif = differenceInCalendarDays(end, start) - 1;

            let cont = item['Next Follow Up Visit Date'];
            let startCont = new Date(cont);
            let difCont = differenceInCalendarDays(end, startCont) - 1;

            return {
              ...item, 'Since': dif, 'Since Contact': difCont, 'ActionDate': actDate,
            };
          });

          // log('newData', newData);
        }
      }

      //omrestart
      else if (window.location.href.toLowerCase().includes('/om-restart')) {
        response = await OMRestartQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);
            let start = new Date(actDate);
            let end = Date.now();
            let dif = differenceInCalendarDays(end, start) - 1;

            let cont = item['Next Follow Up Visit Date'];
            let startCont = new Date(cont);
            let difCont = differenceInCalendarDays(end, startCont) - 1;

            const lastConTime = item['Last Contacted Time'] ? dayjs('1/1/1 ' + item['Last Contacted Time']).format('hh:mm A') : '';
            const nfVisitTime = item['Next Follow-up Visit Time'] ? dayjs('1/1/1 ' + item['Next Follow-up Visit Time']).format('hh:mm A') : '';

            return {
              ...item, 'Since': dif, 'Since Contact': difCont, 'ActionDate': actDate, 'Last Contacted Time': lastConTime, 'Next Follow-up Visit Time': nfVisitTime,
            };
          });

           //log('newData', newData);
        }
      }

       //omincompletemovement
       else if (window.location.href.toLowerCase().includes('/om-incompletemovement')) {
        response = await OMInCompleteMovementQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);

            const movConsultTime = item['Movement Consult Time'] ? dayjs('1/1/1 ' + item['Movement Consult Time']).format('hh:mm A') : '';

            return {
              ...item, 'ActionDate': actDate, 'DOB': item.DOB.substring(0, 10), 'Movement Consult Time': movConsultTime, 'ContactNo': item.ContactNo.replace(/[^\d]/g, ''),
            };
          });

           //log('newData', newData);
        }
      }

      //omabnneeded
      else if (window.location.href.toLowerCase().includes('/om-abnneeded')) {
        response = await OMABNNeededQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);

            const nfVisitTime = item['Next Follow-up Visit Time'] ? dayjs('1/1/1 ' + item['Next Follow-up Visit Time']).format('hh:mm A') : '';

            return {
              ...item, 'ActionDate': actDate, 'DOB': item.DOB.substring(0, 10), 'Next Follow-up Visit Time': nfVisitTime,
            };
          });

           //log('newData', newData);
        }
      }

      //omactivewithoutnfv
      else if (window.location.href.toLowerCase().includes('/om-activewithoutnfv')) {
        response = await OMActiveWithoutNFVQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);

            return {
              ...item, 'ActionDate': actDate, 'DOB': item.DOB.substring(0, 10), 'ContactNo': item.ContactNo.replace(/[^\d]/g, ''),
            };
          });

           //log('newData', newData);
        }
      }

      //om-npafaxed
      else if (window.location.href.toLowerCase().includes('/om-npafaxed')) {

        startDate = dayjs(Date.now()).subtract(90, 'day').format('YYYY/MM/DD');
        endDate = dayjs(Date.now()).format('YYYY/MM/DD');

        response = await OMNPAFaxedQuery();
        if (response.IsSuccess) {

          let respData = response.Data.Data.filter((fiIt: any) => fiIt['LeadSource'] === 'Referral by Healthcare Professional');

          newData = respData.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);
            const npVisitTime = item['NP Visit-Time'] ? dayjs('1/1/1 ' + item['NP Visit-Time']).format('hh:mm A') : '';

            return {
              ...item, 'ActionDate': actDate, 'DOB': item.DOB.substring(0, 10), 'NPVisitDate': item['NP Visit-Date'], 'NP Visit-Time': npVisitTime,
            };
          });

           //log('newData', newData);
        }
      }

      //om-unabletoverify
      else if (window.location.href.toLowerCase().includes('/om-unabletoverify')) {

        startDate = dayjs('2008/01/01').format('YYYY/MM/DD');
        endDate = dayjs(Date.now()).add(730, 'day').format('YYYY/MM/DD');

        response = await OMUnableToVerifyQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);

            return {
              ...item, 'ActionDate': actDate, 'DOB': item.DOB.substring(0, 10), 'NPVisitDate': item['NP Visit-Date']
            };
          });

           //log('newData', newData);
        }
      }

      //om-npabeforetoday
      else if (window.location.href.toLowerCase().includes('/om-npabeforetoday')) {

        startDate = dayjs('2021-01-01').format('YYYY/MM/DD');
        endDate = dayjs(Date.now()).subtract(1, 'day').format('YYYY/MM/DD');

        response = await OMNPABeforeTodayLeadsQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);

            return {
              ...item, 'ActionDate': actDate, 'DOB': item.DOB.substring(0, 10), 'NPVisitDate': item['NP Visit-Date']
            };
          });

           //log('newData', newData);
        }
      }

      //om-bhstatus
      else if (window.location.href.toLowerCase().includes('/om-bhstatus')) {

        response = await OMBehavioralHealthStatusQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);
            const bhFollowTime = item['BH Follow Up Time'] ? dayjs('1/1/1 ' + item['BH Follow Up Time']).format('hh:mm A') : '';

            return {
              ...item, 'ActionDate': actDate, 'DOB': item.DOB.substring(0, 10), 'BH Follow Up Time': bhFollowTime, 'ContactNo': item.ContactNo.replace(/[^\d]/g, '')
            };
          });

           //log('newData', newData);
        }
      }

      //om-missingbhpaperwork
      else if (window.location.href.toLowerCase().includes('/om-missingbhpaperwork')) {

        startDate = dayjs(Date.now()).format('YYYY/MM/DD');
        endDate = dayjs(Date.now()).add(30, 'day').format('YYYY/MM/DD');

        response = await OMMissingBHPaperworkQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);
            const bhFollowTime = item['BH Follow Up Time'] ? dayjs('1/1/1 ' + item['BH Follow Up Time']).format('hh:mm A') : '';

            return {
              ...item, 'ActionDate': actDate, 'DOB': item.DOB.substring(0, 10), 'BH Follow Up Time': bhFollowTime, 'BHInitialVisitDate': item['BH Initial Visit Date'],
            };
          });

           //log('newData', newData);
        }
      }

      //om-unblverifymanagedins
      else if (window.location.href.toLowerCase().includes('/om-unblverifymanagedins')) {

        startDate = dayjs('2021-01-01').format('YYYY/MM/DD');
        endDate = dayjs(Date.now()).add(180, 'day').format('YYYY/MM/DD');

        response = await OMUnableVerifyInsManagedQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);

            return {
              ...item, 'ActionDate': actDate, 'DOB': item.DOB.substring(0, 10), 'NFVisitDate': item['Next Follow Up Visit Date'],
            };
          });

           //log('newData', newData);
        }
      }

      //om-parequired
      else if (window.location.href.toLowerCase().includes('/om-parequired')) {

        response = await OMPARequiredQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);

            let authVisitRemaining = (isNaN(item['Auth Visits Approved']) || isNaN(item['Auth Visits Used'])) ? '-' : Math.round(parseInt(item['Auth Visits Approved']) - parseInt(item['Auth Visits Used']));

            return {
              ...item, 'ActionDate': actDate, 'DOB': item.DOB.substring(0, 10), 'Remaining Visits': authVisitRemaining,
            };
          });

           //log('newData', newData);
        }
      }

      //newpatientform
      else if (window.location.href.toLowerCase().includes('/newpatientform')) {

        response = await NPAIntakeFormReportQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);
            const npVisitTime = item['NP Visit-Time'] ? dayjs('1/1/1 ' + item['NP Visit-Time']).format('hh:mm A') : '';

            return {
              ...item, 'ActionDate': actDate, 'NPVisitDate': item['NP Visit-Date'], 'NP Visit-Time': npVisitTime,
            };
          });

           //log('newData', newData);
        }
      }

      //newpatientform
      else if (window.location.href.toLowerCase().includes('/programconsentlinks')) {

        response = await ProgramConsentFormReportQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);

            return {
              ...item, 'ActionDate': actDate,
            };
          });

           //log('newData', newData);
        }
      }

      //vobnpa
      else if (window.location.href.toLowerCase().includes('/vobnpa')) {
        response = await VOBNPAQuery();
        if (response.IsSuccess) {

          newData = response.Data.Data.map((item: any) => {
            let npDate = item['NP Visit-Date'];
            let start = new Date(npDate);
            let end = Date.now();
            let dif = differenceInCalendarDays(start, end) + 1;

            let dob = item['DOB'].substring(0, 10);

            let syncDate = convertUTCDateToLocalDate(new Date(item['ServerSyncTimestamp']));
            let syDaSt = syncDate.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' });

            return {
              ...item, 'Days from NPA': dif, Since: dif, DOB: dob, ServerSyncTimestamp: syDaSt,
            };
          });

          // log('newData', newData);
        }
      }
      //patientNavigator
      else {
        response = await patientNavigatorQuery(fetchedForDays, startDate, endDate, duplicateReferralDate);

        if (response.IsSuccess) {
          newData = response.Data.Data.map((item: any) => {
            let actDate = item.ActionDate.substring(0, 10);
            let start = new Date(actDate);
            let end = Date.now();
            let dif = differenceInCalendarDays(end, start) - 1;

            let cont = item['Contact Patient Date'];
            let startCont = new Date(cont);
            let difCont = differenceInCalendarDays(end, startCont) - 1;

            return {
              ...item, 'Since': dif, 'Since Contact': difCont, 'ActionDate': actDate,
            };

          });

          // log('newData', newData);
        }
      }

      if (response.IsSuccess) {
        addFirstFilter();
        sortArray(newData, sortAccessor, sortAsc);

        let date = new Date(response.Data.TimeStamp);
        //04/11/2024, 04:44:27 PM
        setRefreshed(date.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' }));// weekday: 'narrow', 

        setQueryRes(newData);

        applyFiltersMulti(newData);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  function isAccessorANumber(arr: any, accessor: string) {
    return arr.filter((item: any) => !isNaN(item[accessor])).length === arr.length;
  }

  function sortArray(arr: any, accessor: string, asc: boolean = true) {
    // log('sortArray', accessor, asc);

    sortAccessor = accessor;
    sortAsc = asc;

    let sorted: any;
    if (isAccessorANumber(arr, accessor)) {
      sorted = arr.sort((a: any, b: any) => {
        if (a[accessor] === null || a[accessor] === undefined) return 1;
        if (b[accessor] === null || b[accessor] === undefined) return -1;
        if (a[accessor] === null || a[accessor] === undefined && b[accessor] === null || b[accessor] === undefined) return 0;

        let aN = Number(a[accessor]);
        let bN = Number(b[accessor]);

        if (asc)
          return aN > bN ? 1 : -1;
        else
          return bN > aN ? 1 : -1;
      });
    }else if(accessor === 'NP Visit-Time' || accessor === 'Next Follow-up Visit Time' || accessor === 'Movement Consult Time' || accessor === 'BH Follow Up Time'){
      sorted = arr.sort(
        (a: any, b: any) => {
          if (asc)
            return dayjs(dayjs('1/1/1 ' + a[accessor])).isAfter(dayjs(dayjs('1/1/1 ' + b[accessor]))) ? 1 : -1;
          else
            return dayjs(dayjs('1/1/1 ' + b[accessor])).isAfter(dayjs(dayjs('1/1/1 ' + a[accessor]))) ? 1 : -1;
        }
      );
    }else {
      sorted = arr.sort((a: any, b: any) => {
        if (a[accessor] === null || a[accessor] === undefined) return 1;
        if (b[accessor] === null || b[accessor] === undefined) return -1;
        if (a[accessor] === null || a[accessor] === undefined && b[accessor] === null || b[accessor] === undefined) return 0;

        return (
          a[accessor].toString().localeCompare(b[accessor].toString(), 'en', {
            numeric: true,
          }) * (asc ? 1 : -1)
        );
      });
    }

    // log('sorted', sorted);
    return sorted;
  }

  function sortTable(accessor: string, sortOrder: string) {
    // console.log(' accessor, sortOrder', accessor, sortOrder);
    let ar = sortArray(filteredQueryRes, accessor, sortOrder === 'asc' ? true : false);
    setFilteredQueryRes(ar);
    onPageChanged(1);
  }

  const handleFilterChangedQuery = (input: any[], accessor: string, props: any) => {
    // log(input, accessor, props);
    if(accessor === 'Duplicate Referral Date'){
      if(input.length === 0){
       duplicateReferralDate = false;
      }else{
        duplicateReferralDate = true;
      }
    }
    clickOnRefreshHub();
  }

  const clickOnDateRangeFilter = () => {
    applyFiltersMulti();
  }

  const handleDateRangeChanged = (input: any[], props: any) => {
    // log(input, props);
    let today = dayjs(Date.now()).format('YYYY/MM/DD');
    startDate = input[0] || today;
    endDate = input[1] || today;
  }

  //Filters
  //For multi select 
  //This is used by child comp.
  const handleFilterChangedMulti = (input: any[], accessor: string, props: any) => {
    // log(input, accessor);

    let found = filtersMulti.find((item) => item.accessor === accessor);

    if (found === undefined)
      filtersMulti.push({ accessor: accessor, value: input, type: props.type });
    else {
      let index = filtersMulti.indexOf(found);
      filtersMulti[index].value = input;
    }

    applyFiltersMulti();
  };

  const applyFiltersMulti = (data: any = null) => {
    let filtered: any[] = data || queryRes;//filtered query alive
    let con: any[] = [];//concatanation array use to inclusive inside a same filter 

    const currentURL = window.location.href.toLowerCase();

    filtersMulti.forEach((item) => { //item are exclusive 
      con = [];//it needs to restart for each item
      // log('item', item);

      item.value.forEach((it) => {
        let eleVal = it.key ? it.key : it;

        //inclusive: inside the same filter, selecting multiple options
        if (eleVal !== 'All') {

          //include
          if (item.accessor === 'FullName' || item.accessor === 'MRN' || item.accessor === 'ContactNo' || item.accessor === 'Username' || (item.accessor === 'OrganizationName' && currentURL.includes('/newpatientform')) || item.accessor === 'CustomerId')
            con = con.concat(filtered.filter((fiIt) => fiIt[item.accessor] != null && fiIt[item.accessor].toString().toLowerCase().includes(eleVal.toLowerCase())));

          //range
          else if (item.type === 'lowerUpper') {
            let lower = item.value[0];
            let upper = item.value[1];

            con = filtered.filter((fiIt) => fiIt[item.accessor] != null && lower <= fiIt[item.accessor] && fiIt[item.accessor] <= upper);
          }
          //Column Filter
          else if (item.type === 'columnFilter') {
            con = con.concat(filtered.filter((fiIt) => fiIt[eleVal] != null && fiIt[item.accessor] !== ""));
          }
          //Multi Value Check Filter
          else if (item.type === 'checkMultiValueFilter') {
            con = con.concat(filtered.filter((fiIt) => {
              if(fiIt[item.accessor] != null && fiIt[item.accessor].toString().includes(eleVal)){
                if(con.includes(fiIt)) return false;
                else return true;
              }else return false;
            }));
          }
          //Empty Check Filter
          else if (item.type === 'emptyCheckFilter') {
            con = filtered;
          }
          //Blank
          else if (eleVal === 'Blank') {
            con = con.concat(filtered.filter((fiIt) => fiIt[item.accessor] == null || fiIt[item.accessor] == undefined || fiIt[item.accessor].toLowerCase().trim().length === 0));
          }
          //equals
          else{
            con = con.concat(filtered.filter((fiIt) => fiIt[item.accessor] != null && fiIt[item.accessor].toString().toLowerCase() === eleVal.toLowerCase()));
          }
        }
      })

      //the next item is exclusive need to restrain the 'filtered' to what is in 'con'
      //also checking item.value is not empty, is empty on a cleaned filter
      if (item.value.length > 0) filtered = con;
    })

    //in case the last filter was only 1 'All' value then we use 'filtered'
    if (con.length === 0) con = filtered;

    if(currentURL.includes('/om-confirmation') || currentURL.includes('/om-npafaxed')  || currentURL.includes('/om-unabletoverify') || currentURL.includes('/om-npabeforetoday') || currentURL.includes('/newpatientform')){
      con = con.filter((item: any) =>
        dayjs(item.NPVisitDate).isSameOrAfter(dayjs(startDate)) && dayjs(item.NPVisitDate).isSameOrBefore(dayjs(endDate))
      );
    }else if(currentURL.includes('/om-past') || currentURL.includes('/om-unblverifymanagedins')){
      con = con.filter((item: any) =>
        dayjs(item.NFVisitDate).isSameOrAfter(dayjs(startDate)) && dayjs(item.NFVisitDate).isSameOrBefore(dayjs(endDate))
      );
    }else if(currentURL.includes('/om-missingbhpaperwork')){
      con = con.filter((item: any) => {
        return dayjs(item.BHInitialVisitDate).isSameOrAfter(dayjs(startDate)) && dayjs(item.BHInitialVisitDate).isSameOrBefore(dayjs(endDate))
      }
      );
    }

    sortArray(con, sortAccessor, sortAsc);

    //updating data and pagination
    setFilteredQueryRes(con);
    onPageChanged(1, con);
  };

  //For multi select with parenting
  //This is used by child comp.
  const handleFilterParenting = (input: any[], accessor: string, props: any) => {
    // log('handleFilterParenting', input, accessor, props);
    if (accessor != 'OrganizationName') return;

    let res = [];
    for (let i = 0; i < input.length; i++) {
      const el = input[i];
      if (el.key.includes('State')) {
        let ar = props.array.filter((item: any) => el.key.includes(item.cat));
        // log(ar);

        for (let e = 0; e < ar.length; e++) {
          const element = ar[e];
          let found = input.find(item => item.key == element.key);
          if (!found)
            res.push({ cat: el.cat, key: element.key })
        }
      }
    }

    let ar = input.concat(res);
    props.setValue(ar);

    handleFilterChangedMulti(ar, accessor, props);
  }

  function apiErrorMsg() {
    if (API_ERROR === 'Unauthorized_API_Access_Logout') {
      setMsg('You are logged out. Please close this window.');
    }

    if (API_ERROR === 'Unauthorized_Access' || API_ERROR === 'Authentication_Token_Expired') {
      setMsg('Token is expired. Relaunch this window after closing it.');
    }
  }

  const onClickPatientNavigators = () => {
    setQueryRes([]);
    navigate('/corelife/patientnavigator');
  };

  const onClickPatientNavigators2 = () => {
    setQueryRes([]);
    navigate('/corelife/patientnavigator2');
  };

  const onClickVOBNPA = () => {
    setQueryRes([]);
    navigate('/corelife/VOBNPA');
  };

  const onClickVOBReverify = () => {
    setQueryRes([]);
    navigate('/corelife/VOBReverify');
  };

  const onClickVOBUnable = () => {
    setQueryRes([]);
    navigate('/corelife/VOBUnable');
  };

  const onOMReportClick = (reportType: any) => {
    setQueryRes([]);
    navigate(reportType.value);
  };

  const navigateOut = () => {
    setCurrentView('unauth');
    setLoading(false);
    navigate('/corelife/');
  };

  async function onClickCopy(data: any, accessor: string) {
    if(accessor === 'PCFormLink'){
      if(data[accessor] === ''){
        let resp = await generateConsentLinkForTulane(data.CustomerId);
        if (!resp.IsSuccess) return;
        clipToCopy(resp.Data.InviteURL);
        clickOnRefreshHub();
      }else clipToCopy(data[accessor])
    }else clipToCopy(data[accessor])
  }

  const clipToCopy = (data: string) => {
    window.navigator.clipboard.writeText(data);
    toast.success('Copied', { toastId: 'co', pauseOnHover: true, autoClose: 2000, position: "bottom-left" })
  }

  const clickRow = async (row: any, event: any) => {
    log('row', row, 'event', event);
    if(row.NPAJsonAvailable === false || row.PCJsonAvailable === false) return;
    let resp = await getMozzazPortalOAuthToken(row.CustomerId);
    if (!resp.IsSuccess) return;
    let token = resp.Data.JwtToken;

    // log('token', token);
    let url = `${PORTAL()}#/oauth?token=${token}&accessFor=formSubmissionEditor&formName=CL_PIS`
    // log('url', url);

    let link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  };

  async function onClickViewResult(e: any) {
    if(e.NPAJsonAvailable === false || e.PCJsonAvailable === false) return;
    const apiName = e.hasOwnProperty('NPAJsonAvailable') ? 'ViewNPAIntakeFormSubmission' : 'ViewProgramConsentFormSubmission';
    let resp = await getMozzazCustomPortalOAuthToken(e.CustomerId, apiName);
    if (!resp.IsSuccess) return;
    let token = resp.Data.JwtToken;

    const subPathName = e.hasOwnProperty('NPAJsonAvailable') ? '' : 'Tulane/Consent-Form/';
    // log('token', token);
    let url = `${CUSTOM_WEB_PORTAL()}${subPathName}view?token=${token}`
    // log('url', url);

    let link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  } 

  async function onClickAvailabilityView(e: any) {
    if(e.PreferredContactTimeJson === '' || e.PreferredContactTimeJson === null) return;

    setPopupDetails({
      header: "View Availability",
      message: e.PreferredContactTimeJson,
      primaryBtnText: "",
      primaryBtnEvent: undefined,
      secondaryBtnText: "",
      secondaryBtnEvent: () => {
        popupDetails.open = false;
        setPopupDetails(popupDetails);
      },
      open: true,
    })

  }

  const buttons = () => {
    let patNav: any = { text: 'New Lead Report', click: onClickPatientNavigators, variant: 'outlined' };
    let patNav2: any = { text: 'Historic Lead Report', click: onClickPatientNavigators2, variant: 'outlined' };
    let vobNpa: any = { text: 'VOB NPA', click: onClickVOBNPA, variant: 'outlined' };
    let vobReverify: any = { text: 'VOB Reverify', click: onClickVOBReverify, variant: 'outlined' };
    let vobUnable: any = { text: 'VOB Unable', click: onClickVOBUnable, variant: 'outlined' };

    if (process.env.REACT_APP_ENV === 'LOCAL')
      return [patNav, vobNpa, vobReverify, vobUnable];

    let url = window.location.href.toLowerCase();
    if (url.includes('patientnavigator'))
      return [patNav, patNav2];
    if (url.includes('vobnpa') || url.includes('vobreverify') || url.includes('vobunable'))
      return [vobNpa, vobReverify, vobUnable];

    return [];
  };

  function tablePars() {
    let tablePar: any = {};
    tablePar.data = filteredQueryResPage;
    tablePar.onRowClicked = clickRow;
    tablePar.onPageChanged = onPageChanged;
    tablePar.pagesCount = pagesCount;
    tablePar.page = page;
    tablePar.handleSorting = sortTable;
    return tablePar;
  }

  function clearQueryInputs() {
    let url = window.location.href.toLowerCase();

    if (url.includes('/patientnavigator2')) {
      duplicateReferralDate = true;
      startDate = dayjs('2013/01/01').format('YYYY/MM/DD');
      endDate = dayjs(Date.now()).subtract(91, 'day').format('YYYY/MM/DD');
    }
    else if (url.includes('/patientnavigator')) {
      duplicateReferralDate = false;
      startDate = dayjs(Date.now()).subtract(90, 'day').format('YYYY/MM/DD');
      endDate = dayjs(Date.now()).format('YYYY/MM/DD');
    }
    else if (url.includes('/om-confirmation')) {
      startDate = dayjs(Date.now()).format('YYYY/MM/DD');
      endDate = dayjs(Date.now()).add(32, 'day').format('YYYY/MM/DD');
    }
    else if (window.location.href.toLowerCase().includes('/om-past')) {
      startDate = dayjs(Date.now()).subtract(1825, 'day').format('YYYY/MM/DD');
      endDate = dayjs(Date.now()).subtract(1, 'day').format('YYYY/MM/DD');
    }
    else if (window.location.href.toLowerCase().includes('/om-missingbhpaperwork')) {
      startDate = dayjs(Date.now()).format('YYYY/MM/DD');
      endDate = dayjs(Date.now()).add(30, 'day').format('YYYY/MM/DD');
   }
   else if (window.location.href.toLowerCase().includes('/om-unblverifymanagedins')) {
    startDate = dayjs('2021-01-01').format('YYYY/MM/DD');
    endDate = dayjs(Date.now()).add(180, 'day').format('YYYY/MM/DD');
 }
    else if (window.location.href.toLowerCase().includes('/om-npafaxed')) {
       startDate = dayjs(Date.now()).subtract(90, 'day').format('YYYY/MM/DD');
       endDate = dayjs(Date.now()).format('YYYY/MM/DD');
    }
    else if (window.location.href.toLowerCase().includes('/newpatientform')) {
      startDate = dayjs(Date.now()).format('YYYY/MM/DD');
      endDate = dayjs(Date.now()).add(112, 'day').format('YYYY/MM/DD');
   }
  }

  return (
    <IntlProvider messages={{}} locale='de' defaultLocale='en'>
      {currentView !== 'unauth' && (<AppBarMenu
        title= {currentView === 'programconsentlinks' ? 'Program Consent Links' : ''}//CL
        isOMReports={isOMReport(currentView)}
        selectedReport={getSelectedOMReport(currentView)}
        onOMReportChange= {onOMReportClick}
        buttons={buttons()}
        open={open}
      // width={props.props.width}
      />)}

      <Box
        component='div'
        sx={{
          display: 'flex',
          p: 1,
          flexDirection: 'column',
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 1,
        }}
      >

        {currentView === 'patientNavigator' && (<div>
          <PatientNavigator
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            handleQuery={handleFilterChangedQuery}
            handleDate={handleDateRangeChanged}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            onClickAvailabilityView={onClickAvailabilityView}
            navigateOut={navigateOut}
            toast={toast}
            clearQueryInputs={clearQueryInputs}
          />
        </div>)}

        {currentView === 'vobnpa' && (<div>
          <VOBNPA
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
          />
        </div>)}

        {(currentView === 'vobreverify' || currentView === 'vobunable') && (<div>
          <VOBReverifyUnable
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'accountLookup' && (<div>
          <AccountLookup
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-dailyaudit' && (<div>
          <OMDailyAudit
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-confirmation' && (<div>
          <OMNPAConfirmationCalls
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            handleDate={handleDateRangeChanged}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            clickOnDateRangeGo={clickOnDateRangeFilter}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-past' && (<div>
          <OMPast
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            handleDate={handleDateRangeChanged}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            clickOnDateRangeGo={clickOnDateRangeFilter}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-volume' && (<div>
          <OMVolume
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-restart' && (<div>
          <OMRestart
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-incompletemovement' && (<div>
          <OMInCompleteMovement
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-abnneeded' && (<div>
          <OMABNNeeded
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-activewithoutnfv' && (<div>
          <OMActiveWithoutNextFollowUpVisit
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-npafaxed' && (<div>
          <OMNPAFaxed
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            handleDate={handleDateRangeChanged}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            clickOnDateRangeGo={clickOnDateRangeFilter}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-unabletoverify' && (<div>
          <OMUnableVerifyInsurance
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            handleDate={handleDateRangeChanged}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            clickOnDateRangeGo={clickOnDateRangeFilter}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-npabeforetoday' && (<div>
          <OMNPABeforeToday
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            handleDate={handleDateRangeChanged}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            clickOnDateRangeGo={clickOnDateRangeFilter}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-bhstatus' && (<div>
          <OMBehavioralHealth
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            handleDate={handleDateRangeChanged}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            clickOnDateRangeGo={clickOnDateRangeFilter}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-missingbhpaperwork' && (<div>
          <OMMissingBHPaperwork
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleDate={handleDateRangeChanged}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            clickOnDateRangeGo={clickOnDateRangeFilter}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-unblverifymanagedins' && (<div>
          <OMUnableVerifyInsManaged
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleDate={handleDateRangeChanged}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            clickOnDateRangeGo={clickOnDateRangeFilter}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'om-parequired' && (<div>
          <OMPARequired
            onClickCopy={onClickCopy}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'newPatientForm' && (<div>
          <NPAIntakeReport
            onClickCopy={onClickCopy}
            onClickViewResult={onClickViewResult}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            handleDate={handleDateRangeChanged}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            clickOnDateRangeGo={clickOnDateRangeFilter}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}

        {currentView === 'programconsentlinks' && (<div>
          <ProgramConsentLinksReport
            onClickCopy={onClickCopy}
            onClickViewResult={onClickViewResult}
            tablePars={tablePars()}
            clearFilters={clearFilters}
            originData={queryRes}
            handleMul={handleFilterChangedMulti}
            handleParenting={handleFilterParenting}
            refreshed={refreshed}
            count={count}
            fetchOrganizations={fetchOrganizations}
            setLoading={setLoading}
            setRefreshBtnDisable={setRefreshBtnDisable}
            refreshBtnDisable={refreshBtnDisable}
            clickOnRefresh={clickOnRefreshHub}
            clickOnExport={clickOnExport}
            navigateOut={navigateOut}
            currentView={currentView}
          />
        </div>)}


        {currentView === 'unauth' && (<div>{msg}</div>)}

      </Box>
      <ToastContainer position="bottom-center" />

      {popupDetails.open && (
        <PopUp
          open={popupDetails.open}
          header={popupDetails.header}
          message={popupDetails.message}
          primaryBtnText={popupDetails.primaryBtnText}
          primaryBtnEvent={popupDetails.primaryBtnEvent}
          secondaryBtnText={popupDetails.secondaryBtnText}
          secondaryBtnEvent={popupDetails.secondaryBtnEvent}
        ></PopUp>
      )}

    </IntlProvider>
  );
};

export default React.memo(ReportHub);

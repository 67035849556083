import SvgIcon from "@mui/material/SvgIcon";

export const CloseIcon = () => {
  return (
      <SvgIcon>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M25 7.01429L22.9857 5L15 12.9857L7.01429 5L5 7.01429L12.9857 15L5 22.9857L7.01429 25L15 17.0143L22.9857 25L25 22.9857L17.0143 15L25 7.01429Z" fill="#0074BF" />
          </svg>
      </SvgIcon>
  );
};
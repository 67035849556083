import { CustomTable } from '../../components/table';
import { getDropDownValues } from '../../services/ReportAPIService';
import { IntlProvider } from 'react-intl';
import Box from '@mui/material/Box';
import BoxContainer from './components/boxContainer';
import React, { useEffect } from 'react';
import { LoginState } from '../../store/slices/login/type';
import { getState } from '../../store';
import { userPermission } from '../../services/AuthAPIService';
import Header from './components/header';
import { orgParenting, log } from '../../utils/util';
import dayjs from 'dayjs';

export const OMUnableVerifyInsurance = (props: any) => {
  //1st row
  const [locationProp, setLocationProp] = React.useState([]);
  const [leadStatus, setLeadStatus] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [creationDate, setCreationDate] = React.useState([]);

  //drops
  const [leadStatusDrop, setLeadStatusDrop] = React.useState(['']);

  const [loading, setLoading] = React.useState(false);

  const session: LoginState = getState().loginReducer;

  let columns = [
    { label: 'Location', accessor: 'OrganizationName', sortable: true, width: '150px' },
    { label: 'MRN', accessor: 'MRN', sortable: true, width: '120px' },
    { label: 'Patient Name', accessor: 'FullName', sortable: true, width: '200px' },
    { label: 'DOB', accessor: 'DOB', sortable: true, width: '150px' },
    { label: 'Patient Lead Status', accessor: 'Patient Lead Status', sortable: true, width: '180px' },
    { label: 'Status of Benefits', accessor: 'Status of Benefits', sortable: true, width: '180px' },
    { label: 'Insurance Verified ', accessor: 'Insurance Verified', sortable: true, width: '150px' },
    { label: 'NP Visit Date', accessor: 'NP Visit-Date', sortable: true, width: '150px' },
    { label: 'CustomerId', accessor: 'CustomerId', sortable: true, width: '120px' },
    { label: 'Username', accessor: 'Username', sortable: true, width: '300px', actionEvent: props.onClickCopy }
  ];

  // table
  props.tablePars.columns = columns;

  let kindBoxes1: any = {
    type: 'Joy',
    boxes: [
      { name: 'Location', array: orgParenting(), value: locationProp, setValue: setLocationProp, handle: props.handleParenting, accessor: 'OrganizationName', type: 'multiSelectGroup' },
      { name: 'Patient Lead Status', array: leadStatusDrop, value: leadStatus, setValue: setLeadStatus, handle: props.handleMul, accessor: 'Patient Lead Status', type: 'multiSelectGroup' },
      {
        name: 'NP Visit Date', value: creationDate, setValue: setCreationDate, handle: props.handleDate, accessor: 'NPVisitDate', type: 'dateRangePicker',
        resetValue: [
          dayjs('2008/01/01').format('YYYY/MM/DD'),
          dayjs(Date.now()).add(730, 'day').format('YYYY/MM/DD')
        ],
        toast: props.toast,
        clickOnRefresh: props.clickOnDateRangeGo,
        refreshBtnDisable: props.refreshBtnDisable,
      },
      { name: 'Patient Count', value: count, setValue: setCount, type: 'label' },
    ]
  }

  //runs after each render
  useEffect(() => {
    setCount(props.count);
  });

  //runs one time when session is changed
  useEffect(() => {
    if (!session.isLoggedIn || !session.sessionData) {
      return;
    }

    fetchData();

  }, [session]);

  const map = [
    { key: 'Patient Lead Status', setValue: setLeadStatusDrop },
  ];

  const fetchData = async () => {
    setLoading(true);

    let r3 = await userPermission('om-unabletoverify');
    if (!r3.IsSuccess) {
      props.navigateOut();
      return;
    }

    if (props.originData.length === 0)
      await props.fetchOrganizations();

    try {
      // log('fetchData');

      map.forEach(async (it) => {
        let response = await getDropDownValues(it.key, 'om-unabletoverify');
        if (response.IsSuccess)
          it.setValue((response.Data.Data.map((item: any) => item.Response)));
      });

      setResetVals();

    } catch (error) {
    } finally {
      setLoading(false);
      props.setRefreshBtnDisable(false);
    }
  };

  //pre selection
  function setResetVals() {
    let boxes = [...kindBoxes1.boxes];

    for (let i = 0; i < boxes.length; i++) {
      const el = boxes[i];
      if (el.resetValue)
        el.setValue(el.resetValue);
    }
  }

  function clearAllFilters() {
    //restart all filters 
    setLocationProp([]);
    setLeadStatus([]);

    setResetVals();
    props.clearFilters();
  }

  return (
    <IntlProvider messages={{}} locale='de' defaultLocale='en'>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Header
            clearAllFilters={clearAllFilters}
            clickOnRefresh={props.clickOnRefresh}
            clickOnExport={props.clickOnExport}
            refreshBtnDisable={props.refreshBtnDisable}
            refreshed={props.refreshed}
            title={'Unable to Verify Insurance (Leads)'}
          />
          <BoxContainer  {...kindBoxes1} />
          <div><p></p></div>
          <CustomTable {...props.tablePars} />
        </Box>
      </Box>
    </IntlProvider>
  );
};

export default React.memo(OMUnableVerifyInsurance);
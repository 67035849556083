import { CustomTable } from '../../../components/table';
import { IntlProvider } from 'react-intl';
import Box from '@mui/material/Box';
import BoxContainer from '../components/boxContainer';
import React, { useEffect } from 'react';
import { LoginState } from '../../../store/slices/login/type';
import { getState } from '../../../store';
import { userPermission } from '../../../services/AuthAPIService';
import Header from '../components/header';

export const ProgramConsentLinksReport = (props: any) => {
  //1st row
  const [count, setCount] = React.useState(0);
  const [org, setOrg] = React.useState([]);
  const [customerId, setCustomerId] = React.useState([]);
  const [username, setUsername] = React.useState([]);
  const [name, setName] = React.useState([]);
  const [creationDate, setCreationDate] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const session: LoginState = getState().loginReducer;

  let columns = [
    { label: 'CustomerId', accessor: 'CustomerId', sortable: true, width: '120px' },
    { label: 'Username', accessor: 'Username', sortable: true, width: '180px', actionEvent: props.onClickCopy },
    { label: 'Name', accessor: 'FullName', sortable: true, width: '180px' },
    { label: 'Program Consent Link', accessor: 'PCFormLink', sortable: true, width: '200px', type: 'underline', actionEvent: props.onClickCopy },
    { label: 'Consent Results', accessor: 'PCJsonAvailable', sortable: true, width: '150px', action: 'resultView', actionEvent: props.onClickViewResult },
  ];

  // table
  props.tablePars.columns = columns;

  let kindBoxes1: any = {
    type: 'Joy',
    boxes: [
      { name: 'Customer ID', value: customerId, setValue: setCustomerId, handle: props.handleMul, accessor: 'CustomerId', type: 'textField' },
      { name: 'Username', value: username, setValue: setUsername, handle: props.handleMul, accessor: 'Username', type: 'textField' },
      { name: 'Name', value: name, setValue: setName, handle: props.handleMul, accessor: 'FullName', type: 'textField' },
      { name: 'Patient Count', value: count, setValue: setCount, type: 'label' },
    ]
  }

  //runs after each render
  useEffect(() => {
    // log('render vob npa');
    setCount(props.count);
  });

  //runs one time when session is changed
  useEffect(() => {
    if (!session.isLoggedIn || !session.sessionData) {
      return;
    }

    fetchData();

  }, [session]);

  const fetchData = async () => {
    setLoading(true);

    let r3 = await userPermission('programconsentlinks');
    if (!r3.IsSuccess) {
      props.navigateOut();
      return;
    }

    if (props.originData.length === 0)
      await props.fetchOrganizations();

    setLoading(false);
    props.setRefreshBtnDisable(false);
  };

  //pre selection
  function setResetVals() {
    let boxes = [...kindBoxes1.boxes];

    for (let i = 0; i < boxes.length; i++) {
      const el = boxes[i];
      if (el.resetValue)
        el.setValue(el.resetValue);
    }
  }

  function clearAllFilters() {

    setName([]);
    setCustomerId([]);
    setUsername([]);

    setResetVals();
    props.clearFilters();
  }

  return (
    <IntlProvider messages={{}} locale='de' defaultLocale='en'>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Header
            clearAllFilters={clearAllFilters}
            clickOnRefresh={props.clickOnRefresh}
            clickOnExport={props.clickOnExport}
            refreshBtnDisable={props.refreshBtnDisable}
            refreshed={props.refreshed}
            title={'Program Consent Links'}
          />
          <BoxContainer  {...kindBoxes1} />
          <div><p></p></div>
          <CustomTable {...props.tablePars} />
        </Box>
      </Box>
    </IntlProvider>
  );
};

export default React.memo(ProgramConsentLinksReport);
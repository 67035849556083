import { Column } from './table';
import { convertToLocal } from '../../utils/datetime';
import { Box, IconButton, Link } from '@mui/material';
import { returnWidth, isColumnNumeric } from './util';
import { table } from 'console';
import { uuidv4 } from '../../utils/util';
import { withStyles } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export interface TableBodyProps {
  columns: Column[];
  tableData: any;
  onRowClicked: Function;
}

const CustomTableBody = ({ columns, tableData, onRowClicked }: TableBodyProps) => {

  const getValue = (column: Column, data: any) => {
    let value = data[column.accessor] ? data[column.accessor] : '';
    if (data[column.accessor] === 0) value = 0;

    switch (column.type) {
      case 'date':
        return convertToLocal(value);
      case 'image':
        const fallback = column.imageFallBack ? data[column.imageFallBack] : '';
        return <Avatar sx={{ bgcolor: 'primary.main' }} alt={fallback} src={`${value}`} />;
      case 'button':
        return <Button>b {value}</Button>;
      case 'underline':
        return <Box sx={{
          fontSize: 14,
          fontFamily: 'ProximaNovaMedium',
          textTransform: 'none',
          lineHeight: 1,
          color: 'primary.main',
          p: 0,
        }}>{column.accessor === 'NPAFormLink' ? 'NPA Form Link' : 'Program Consent Link'}</Box>
      default:
        return value;
    }
  };

  const getActionButton = (column: Column, data: any) => {
    switch (column.action) {
      case 'view':
        return (
          <Button
            sx={{
              fontSize: 14,
              fontFamily: 'ProximaNovaMedium',
              textTransform: 'none',
              lineHeight: 1,
              p: 0,
            }}
            variant="text"
            startIcon={<VisibilityOutlinedIcon color="primary" />}
            onClick={(event: any) => {
              event.stopPropagation();
              if (column.actionEvent) column.actionEvent(data);
            }}
          >
            View
          </Button>
        );
      case 'edit':
        return (
          <Button
            sx={{
              fontSize: 14,
              fontFamily: 'ProximaNovaMedium',
              textTransform: 'none',
              lineHeight: 1,
              p: 0,
            }}
            variant="text"
            startIcon={<EditIcon color="primary" />}
            onClick={(event: any) => {
              event.stopPropagation();
              if (column.actionEvent) column.actionEvent(data);
            }}
          >
            Edit
          </Button>
        );
        case 'resultView':
        return (
          <Button
            sx={{
              fontSize: 16,
              fontFamily: 'ProximaNovaSoftSemibold',
              textTransform: 'none',
              lineHeight: 1,
              color: data[column.accessor] === true ? 'primary.main' : 'custom_light_grey.main',
              p: 0,
              cursor: 'pointer'
            }}
            variant="text"
            onClick={(event: any) => {
              if(data[column.accessor] === true){
                event.stopPropagation();
                if (column.actionEvent) column.actionEvent(data);
              }
            }}
          >
            Result
          </Button>
        );
        case 'clickable':
        return (
          <Button
            sx={{
              fontSize: 16,
              fontFamily: 'ProximaNovaSoftSemibold',
              textTransform: 'none',
              lineHeight: 1,
              color: (data[column.accessor] === "" || data[column.accessor] === null) ? 'custom_light_grey.main' : 'primary.main',
              p: 0,
              cursor: 'pointer'
            }}
            variant="text"
            onClick={(event: any) => {
              if(data[column.accessor] === true){
                event.stopPropagation();
                if (column.actionEvent) column.actionEvent(data);
              }
            }}
          >
            View Availability
          </Button>
        );
    }
  };

  const getValueAndActionButton = (column: Column, data: any) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {column.accessor === 'PCFormLink' ? getValue(column, data) : data[column.accessor] && getValue(column, data)}
        {column.accessor === 'PCFormLink' ?  <ContentCopyIcon sx={{ fontSize: 28, paddingLeft: 1, color: '#0074bf' }} /> : data[column.accessor] && <ContentCopyIcon sx={{ fontSize: 28, paddingLeft: 1, color: '#0074bf' }} />}
      </div>
    );
  }

  const renderCell = (column: Column, data: any) => {
    if (column.accessor == 'Username' || column.accessor == 'NPAFormLink' || column.accessor == 'PCFormLink' || column.type == 'emailForNPA') return getValueAndActionButton(column, data);

    return column.action === undefined ? getValue(column, data) : getActionButton(column, data)
  }

  return (
    <tbody>
      {tableData.map((data: any) => {
        // console.log(data);

        return (
          <TableRow
            key={uuidv4().substring(0, 7)}
            hover={true}
            onClick={(event: any) => {
              onRowClicked(data, event);
            }}
            style={{ cursor: 'pointer' }}
          >
            {columns.map((column: any) => {
              // console.log(column, data);

              return (
                <TableCell
                  title={(column.accessor == 'Username'  || column.accessor == 'NPAFormLink' || column.accessor == 'PCFormLink') ? 'Copy to clipboard' : undefined}
                  key={uuidv4().substring(0, 7)}
                  sx={{
                    color: 'custom_dark_grey.main',
                    fontFamily: 'ProximaNova',
                    fontSize: 18,
                  }}
                  style={{
                    overflowWrap: 'normal',
                    inlineSize: returnWidth(column, columns),
                    minWidth: returnWidth(column, columns),
                    padding: 8, border: "1px solid rgba(224, 224, 224, 1)",
                    textAlign: column.isRightAlign ? 'right' : 'left',
                  }}
                  onClick={(event: any) => {
                    if (column.accessor == 'Username' || column.accessor == 'NPAFormLink' || column.accessor == 'PCFormLink' || column.accessor == 'PreferredContactTimeJson'){
                      event.stopPropagation();
                    }
                    if (column.actionEvent) column.actionEvent(data, column.accessor);
                  }}
                >
                  {renderCell(column, data)}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </tbody>
  );
};

export default CustomTableBody;

import axios from 'axios';
import { BACKEND_ENDPOINT } from '../config/Constant';
import { requestTemplate } from './RequestTemplate';
import { getState, dispatch, actions } from '../store';
import { LoginData, LoginState } from '../store/slices/login/type';
import { retFlagName } from './UtilAPI';
import { log } from '../utils/util';

export let API_ERROR = '';

export async function getMozzazPortalOAuthToken(targetCustomerId: any) {
  log('getMozzazPortalOAuthToken');
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'getMozzazPortalOAuthToken',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      accessFor: "formSubmissionEditor",
      customerId: session.sessionData.CustomerId,
      targetCustomerId: targetCustomerId
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    log(data.err);
    return;
  }

  return data;
}

export async function getMozzazCustomPortalOAuthToken(targetCustomerId: any, accessForName: string) {
  log('getMozzazCustomPortalOAuthToken');
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'GetMozzazCustomPortalOAuthToken',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      accessFor: accessForName,
      customerId: session.sessionData.CustomerId,
      targetCustomerId: targetCustomerId
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    log(data.err);
    return;
  }

  return data;
}

export async function generateConsentLinkForTulane(targetCustomerId: any) {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'GenerateConsentLinkTulane',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: targetCustomerId
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    log(data.err);
    return;
  }

  return data;
}

export async function validateToken(JwtToken: any) {
  // log('validateToken');

  let req = {
    API_NAME: 'ReportAppValidateToken',
    API_AUTHENTICATION_TYPE: 'auth_none',
    API_AUTHENTICATION_TOKEN: '',
    AUTHENTICATION_OWNER_ID: '',
    API_PAYLOAD: {
      Token: JwtToken,
      fastReportFlagName: "CL_FastReport_PatientNavigator",
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    log(data.err);
    return;
  }

  return data;
}

export async function mozzazReportingWebPortalLogin(JWTToken: any) {
  // log('mozzazReportingWebPortalLogin');

  let req = {
    API_NAME: 'MozzazReportingWebPortalLogin',
    API_AUTHENTICATION_TYPE: 'auth_none',
    API_AUTHENTICATION_TOKEN: '',
    AUTHENTICATION_OWNER_ID: '',
    API_PAYLOAD: {
      JWTToken: JWTToken,
      fastReportFlagName: "CL_FastReport_PatientNavigator",
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    log(data.err);
    return;
  }

  return data;
}

export async function userPermission(reportName: any) {
  // log('userPermission');

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'verifyUserAccessToReport',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      customerId: session.sessionData.CustomerId,
      fastReportFlagName: retFlagName(reportName),
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    log(data.err);
    return;
  }

  return data;
}

export async function login(username: any, password: any) {
  log('login', username, password);

  let req = {
    API_NAME: 'mozzazpstoollogin',
    API_AUTHENTICATION_TYPE: 'auth_none',
    API_AUTHENTICATION_TOKEN: '',
    API_PAYLOAD: {
      Username: username,
      Password: password,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(BACKEND_ENDPOINT(), req);
  log(BACKEND_ENDPOINT(), 'req', req, 'response', data);
  handleUnauthorizedUser(data);
  // debugger;

  if (!data || data.err || !data.IsSuccess) {
    log('login err:', data);
    return data;
  }

  dispatch(actions.loginActions.loginSuccess(data.Data as LoginData));

  const session: LoginState = getState().loginReducer;
  log('session.sessionData', session.sessionData);
  return data;
}

export async function logout() {
  log('logout');

  // localStorage.setItem('SecretKey', '');
  // localStorage.setItem('Env', '');

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'MozzazReportingWebPortalLogout',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      customerId: session.sessionData.CustomerId,
      sessionToken: session.sessionData.AuthToken,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    log(data.err);
    return;
  }

  // if(data.IsSuccess)
  //   dispatch(actions.loginActions.logout(null));

  return data;
}

export async function forgotPassword(user: any, email: any) {
  log('forgotPassword');

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }
  log('session.sessionData.', session.sessionData);

  let req = {
    API_NAME: 'mozzazpstoolforgotpassword',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      Username: session.sessionData.Username,
      Email: session.sessionData.Email,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    log(data.err);
    return;
  }

  return data;
}

export async function changePassword(oldPassword: any, newPassword: any) {
  log('changePassword');

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazpstoolchangepassword',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
      OldPassword: oldPassword,
      NewPassword: newPassword,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    log(data.err);
    return;
  }

  return data;
}

export async function saveNewPassword(newPassword: any, auth: any) {
  log('resetPassword');

  let req = {
    API_NAME: 'mozzazportalsavenewpassword',
    API_AUTHENTICATION_TYPE: 'auth_none',
    API_AUTHENTICATION_TOKEN: auth,
    API_PAYLOAD: {
      Password: newPassword,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    log(data.err);
    return;
  }

  return data;
}

export async function handleUnauthorizedUser(data: any) {
  // log('handleUnauthorizedUser', data);

  if (data && !data.IsSuccess) {
    if (data.Data.Error === 'Unauthorized_API_Access_Logout' && data.Data.Error_Type === 'API_ERROR')
      LogoutActions(data);
    else if (data.Data.Error_Type === 'ACCESS_ERROR') {
      if (data.Data.Error === 'Unauthorized_Access' || data.Data.Error === 'Authentication_Token_Expired')
        LogoutActions(data);
    }

  }
  if (data && data.IsSuccess) {
    if (data.Data.Success === 'Session deleted successfully') {
      API_ERROR = 'Unauthorized_API_Access_Logout';
    }
  }
}

async function LogoutActions(data: any) {
  API_ERROR = data.Data.Error;
  await logout();
  dispatch(actions.loginActions.logout(null));
}
